
/*
* Converts Formik timepicker value into UTC time
* 
* Input: 0000-00-00T00:00:00.000-00.00
* Output: 0000-00-00T00:00:00.000Z
* 
* */
export const ConvertUTCTime = (time : string)=>{
    return  new Date(time).toISOString()
}