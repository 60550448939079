import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import {PageTitle} from "../../../../functions/PageTitleFunction";
import AdminFleetCreationForm from "./FleetCreation/AdminFleetCreationForm";
import {useOktaAuth} from "@okta/okta-react";
import {FleetApi, ListItemDto} from "../../../../api/TrailerPMApi.generated";
import {ToastAlert} from "../../../global/Toast/ToastAlert";
import {useGlobalLoading} from "../../../../context/LoadingContext";
import MonoTable from "../../../global/table/MonoTable";
import {ColDefMonoTable} from "../../../../interfaces/interfaces";


const AdminFleetsPage = () => {
  PageTitle("Fleet Admin");
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const fleetApi = new FleetApi();
  fleetApi.token = token;
  const { setLoadingData, loadingData } = useGlobalLoading();
  
  const [ fleets, setFleets ] = useState<any[]>([]);
  const getFleets = async () => {
      setLoadingData(true)
      await fleetApi.getAllFleets()
          .then((fleets) => {
              setFleets(fleets);
              setLoadingData(false);
          })
          .catch(err => {
              setLoadingData(false);
              ToastAlert("error", `Error gathering fleets: ${err}`);
          })
  }
  
    const columns: ColDefMonoTable = [
        {
            name: "display",
            label: "Name"
        }
    ]
    
    useEffect(() => {
      getFleets();
  }, [])
  
  return (
      <Box mt={2}>
        <Grid
            container
        >
          <Grid item xs={12} my={3}>
              <AdminFleetCreationForm getFleets={getFleets} fleetApi={fleetApi}/>
          </Grid>
          <Grid item xs={12}>
              <MonoTable columns={columns} rows={fleets} title={"Fleet Admin"} />
          </Grid>
        </Grid>
      </Box>
  );
};

export default AdminFleetsPage;
