import ProviderBusinessHours from "./ProviderBusinessHours";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ProviderServices from "./ProviderServices";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { PageTitle } from "../../../../functions/PageTitleFunction";

const ProviderConfig = () => {
  PageTitle(`Services`);

  return (
    <Grid container>
      <Grid item xs={8} mt={2}>
        <Accordion defaultExpanded>
          <AccordionSummary>
            <Typography variant="subtitle1">Service Availability</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProviderBusinessHours />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={8} mt={2}>
        <Accordion defaultExpanded>
          <AccordionSummary>
            <Typography variant="subtitle1">Service Pricing</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProviderServices />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default ProviderConfig;
