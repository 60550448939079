import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useOktaAuth } from "@okta/okta-react";
import { requestUserProfile } from "../adapters/RequestUserProfile";
import { UserApi, UserDto } from "../api/TrailerPMApi.generated";

export type UserContextType = {
  currentUser: UserDto | null;
  userInitials: string;
  token: string | undefined;
  setCurrentUser: (user: UserDto) => void;
  createUserInitials: (user: UserDto) => void;
};

export const UserContext = createContext<UserContextType>({
  currentUser: null,
  setCurrentUser: (user: UserDto) => {},
  userInitials: "",
  createUserInitials: (user: UserDto) => {},
  token: undefined,
});

export const useUserContext = () => {
  const currentUserContext = useContext(UserContext);
  if (!currentUserContext) {
    throw new Error("Cannot obtain user context outside of UserProvider");
  }
  return currentUserContext;
};

const UserProviderContext = ({ children }: any) => {
  const [currentUser, setUser] = useState<UserDto | null>(null);
  const [userInitials, setUserInitials] = useState("");
  const { oktaAuth, authState } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  const setCurrentUser = useCallback((user: UserDto) => {
    setUser(user);
    setUserInitials(createUserInitials(user));
  }, []);

  const createUserInitials = (user: UserDto) => {
    if (!user) {
      return "";
    }
    const initials = `${user?.firstName?.[0]}.${user.lastName?.[0]}.`;
    return initials;
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const userApi = new UserApi();
      userApi.token = token;
      userApi
        .getUserProfile()
        .then((profile) => {
          setCurrentUser(profile);
        })
        .catch((err) => {
          console.log("GetUserProfile error", err);
        });
    }
  }, [token, authState?.isAuthenticated, setCurrentUser]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        createUserInitials,
        userInitials,
        token,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = UserProviderContext;
