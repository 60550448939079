import React, { createContext, useState } from "react";

export type StatesContextType = {
  statesInfo: string[];
  setStatesInfo: (states: string[]) => void;
};

export const StatesContext = createContext<StatesContextType>({
  statesInfo: [],
  setStatesInfo: (states: string[]) => {},
});

const StatesProvider = ({ children }: any) => {
  const statePostalAbb = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const [statesInfo, setStatesInfo] = useState(statePostalAbb);

  return (
    <StatesContext.Provider
      value={{
        statesInfo,
        setStatesInfo,
      }}
    >
      {children}
    </StatesContext.Provider>
  );
};

export default StatesProvider;
