import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LinearProgress, Switch } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import { useOktaAuth } from "@okta/okta-react";
import {
  ProviderHoursApi,
  ProviderHoursDto,
} from "../../../../api/TrailerPMApi.generated";
import { useUserContext } from "../../../../context/UserProvider";
import { ConvertUTCTime } from "../../../../functions/ConvertUTCTime";
import { useGlobalLoading } from "../../../../context/LoadingContext";

const ProviderBusinessHours = () => {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const providerHoursApi = new ProviderHoursApi();
  const { currentUser } = useUserContext();
  const { setGlobalLoading } = useGlobalLoading();

  const initialDayValues = [
    new ProviderHoursDto({
      dayOfWeek: "Sunday",
      openValue: true,
      openTime: null,
      closeTime: null,
    }),
    new ProviderHoursDto({
      dayOfWeek: "Monday",
      openValue: true,
      openTime: null,
      closeTime: null,
    }),
    new ProviderHoursDto({
      dayOfWeek: "Tuesday",
      openValue: true,
      openTime: null,
      closeTime: null,
    }),
    new ProviderHoursDto({
      dayOfWeek: "Wednesday",
      openValue: true,
      openTime: null,
      closeTime: null,
    }),
    new ProviderHoursDto({
      dayOfWeek: "Thursday",
      openValue: true,
      openTime: null,
      closeTime: null,
    }),
    new ProviderHoursDto({
      dayOfWeek: "Friday",
      openValue: true,
      openTime: null,
      closeTime: null,
    }),
    new ProviderHoursDto({
      dayOfWeek: "Saturday",
      openValue: true,
      openTime: null,
      closeTime: null,
    }),
  ];

  const [existingHours, setExistingHours] =
    useState<ProviderHoursDto[]>(initialDayValues);
  const [disabledMessage, setDisabledMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const getCurrentHours = () => {
    if (currentUser?.serviceProviderId) {
      setLoading(true);
      providerHoursApi.token = token;
      providerHoursApi
        .getProviderHours(currentUser?.serviceProviderId)
        .catch((error) => {
          setLoading(false);
        })
        .then((data) => {
          if (data) {
            if (data.length) {
              const daysOfWeek = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ];
              data.sort((a, b) => {
                console.log(a.dayOfWeek);
                //@ts-ignore
                return (
                  daysOfWeek.indexOf(a.dayOfWeek ?? "") -
                  daysOfWeek.indexOf(b.dayOfWeek ?? "")
                );
              });
              setExistingHours(data);
            }
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getCurrentHours();
  }, [currentUser]);

  const handleDisable = (values: any) => {
    let errorLines = values.days.filter((x: any) => {
      return (
        x.openValue === true && (x.openTime === null || x.closeTime === null)
      );
    });

    if (errorLines.length > 0) {
      setDisabledMessage(true);
    }
    if (errorLines.length === 0) {
      saveHours(values);
      setDisabledMessage(false);
    }
  };

  const saveHours = async (schedule: any) => {
    setGlobalLoading(true);
    schedule.days.forEach((day: any) => {
      if (day.openTime != null) {
        day.openTime = ConvertUTCTime(day.openTime);
      }
      if (day.closeTime != null) {
        day.closeTime = ConvertUTCTime(day.closeTime);
      }
    });
    providerHoursApi.token = token;
    if (currentUser?.serviceProviderId) {
      await providerHoursApi
        .setProviderHours(currentUser?.serviceProviderId, schedule.days)
        .then(() => {
          getCurrentHours();
          setGlobalLoading(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{ days: existingHours }}
      enableReinitialize
      onSubmit={(values, actions) => {
        handleDisable(values);
        actions.setSubmitting(false);
      }}
    >
      {(formik) => {
        const { handleChange, setFieldValue, values } = formik;
        // console.log(values)
        return (
          <div>
            {loading ? (
              <LinearProgress />
            ) : (
              <Form onChange={(e) => []}>
                {values.days?.map((day, index) => (
                  <Grid
                    item
                    xs={12}
                    container
                    my={1}
                    key={index}
                    sx={{ maxWidth: "800px" }}
                  >
                    <Grid item xs={6} md={2}>
                      <Typography variant="h6">{day.dayOfWeek}</Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Stack direction="row" alignItems="center">
                        <Switch
                          checked={day.openValue}
                          onChange={(value) => {
                            handleChange(value);
                            if (day.openValue) {
                              setFieldValue(`days[${index}].openTime`, null);
                              setFieldValue(`days[${index}].closeTime`, null);
                            }
                          }}
                          name={`days[${index}].openValue`}
                          value={day.openValue}
                        />
                        <Typography>
                          {day.openValue ? "Open" : "Closed"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={5} md={3}>
                      <TimePicker
                        disabled={!day.openValue}
                        label="Open"
                        value={day.openTime}
                        onChange={(value) => {
                          setFieldValue(`days[${index}].openTime`, value);
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignContent="center"
                      xs={1}
                      md={1}
                    >
                      <Typography component="span">To</Typography>
                    </Grid>
                    <Grid item xs={5} md={3}>
                      <TimePicker
                        disabled={!day.openValue}
                        label="Close"
                        value={day.closeTime}
                        onChange={(value) =>
                          setFieldValue(`days[${index}].closeTime`, value)
                        }
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
                {disabledMessage && (
                  <Grid item container xs={12} justifyContent="center" mt={5}>
                    <Typography>
                      Please input hours for available days
                    </Typography>
                  </Grid>
                )}
                <Grid item container xs={12} justifyContent="center" mt={5}>
                  <Button type="submit" variant="contained">
                    Save Schedule
                  </Button>
                </Grid>
              </Form>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default ProviderBusinessHours;
