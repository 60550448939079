import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../../functions/PageTitleFunction";
import { useUserContext } from "../../../../context/UserProvider";
import { useOktaAuth } from "@okta/okta-react";
import {
  RequestDto,
  RequestApi,
  GetFleetRequestListDto,
  RequestStatusEnum,
} from "../../../../api/TrailerPMApi.generated";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";
import FleetServiceRequestsTable from "./FleetServiceRequestsTable";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { NonBillingRequestEnums } from "../../../../functions/RequestEnumFunctions";

const FleetServiceRequestsPage = () => {
  PageTitle("Service Requests");
  const { currentUser } = useUserContext();
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const requestApi = new RequestApi();
  const [requests, setRequests] = useState<RequestDto[]>([]);
  // TODO: make persistent in database
  const [lastUpdated, setLastUpdated] = useState<any>();

  const navigate = useNavigate();
  const [redirectDisabled, setRedirectDisabled] = useState<boolean>(true);
  const handleRedirect = (route: string) => {
    setRedirectDisabled(true);
    navigate(`${route}`, { state: { redirectDisabled } });
  };

  const getRequests = async () => {
    requestApi.token = token;
    if (currentUser?.fleetId) {
      return await requestApi.getFleetRequests(
        new GetFleetRequestListDto({
          fleetId: currentUser.fleetId,
          filteredStatuses: NonBillingRequestEnums(),
        })
      );
    } else {
      return [];
    }
  };

  const fetchData = async () => {
    const requestList = await getRequests();
    let date = new Date().toLocaleString();
    setLastUpdated(date);
    setRequests(requestList);
    return requestList;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container direction="column" mt={5}>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5">Requests</Typography>
        </Grid>
        <Grid item>
          <span style={{ fontSize: "12pt", marginRight: "5px" }}>
            Last updated: {lastUpdated}
          </span>
          <Button variant="contained" onClick={fetchData}>
            <RefreshIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item mt={2}>
        <FleetServiceRequestsTable
          requests={requests}
          handleRedirect={handleRedirect}
        />
      </Grid>
    </Grid>
  );
};

export default FleetServiceRequestsPage;
