import React, { useEffect, useState } from "react";
import { Button, FormControl, FormHelperText, Grid } from "@mui/material";
import Box from "@mui/material/Box";

import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import WrongLocationRoundedIcon from '@mui/icons-material/WrongLocationRounded';
import NearMeIcon from "@mui/icons-material/NearMe";
import Divider from "@mui/material/Divider";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const LocationSelector = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  setValues,
  initialValues,
  prefilledAddresses,
  setFieldTouched,
  resetForm
}: {
  values: any;
  touched: any;
  errors: any;
  handleChange: any;
  setFieldValue: any;
  setValues: any;
  initialValues: any;
  prefilledAddresses: any;
  setFieldTouched: any;
  resetForm: any;
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const [isBaseOptionDisabled, setBaseOptionDisabled] = useState<boolean>(true);
  const [isCurrentOptionDisabled, setCurrentOptionDisabled] =
    useState<boolean>(true);

  const handleDisable = () => {
    console.log(values)
    const currentDisabled = !prefilledAddresses.currentAddress;

    const baseDisabled = !prefilledAddresses.baseAddress;

    setCurrentOptionDisabled(currentDisabled);
    setBaseOptionDisabled(baseDisabled);

    // Prefill address fields by current address > base address > none
    if (!currentDisabled) {
      handleAddressTypeChange("currentAddress");
    } else if (!baseDisabled) {
      handleAddressTypeChange("baseAddress");
    }
  };

  const handleAddressTypeChange = (addressType: string) => {
    if(addressType === "manual"){
      setFieldValue("addressLine1", "")
      setFieldValue("addressLine2", "")
      setFieldValue("city", "")
      setFieldValue("state", "")
      setFieldValue("postalCode", "")
    } else {
      
    setFieldValue(
      "addressLine1",
      prefilledAddresses[`${addressType}`].addressLine1
    );
    setFieldValue(
      "addressLine2",
      prefilledAddresses[`${addressType}`].addressLine2
    );
    setFieldValue("city", prefilledAddresses[`${addressType}`].city);
    setFieldValue("state", prefilledAddresses[`${addressType}`].state);
    setFieldValue(
      "postalCode",
      prefilledAddresses[`${addressType}`].postalCode
    );
    
    setTimeout(() => {
      setFieldTouched("city", true);
    });
    }
  };

  useEffect(() => {
    handleDisable();
  }, [prefilledAddresses]);

  return (
    <Box sx={{ paddingTop: 1 }}>
      <Grid item container direction="row" spacing={1} alignItems="center">
        <Grid item container direction="row" spacing={1} md={12} lg={12}>
          <Grid item sm={12} md={6} lg={6}>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="addressLine1"
                name="addressLine1"
                label="Street 1"
                value={values.addressLine1}
                size="small"
                fullWidth={true}
                onChange={handleChange}
                error={touched.addressLine1 && Boolean(errors.addressLine1)}
              />
              <FormHelperText>
                {touched.addressLine1 && errors.addressLine1}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="addressLine2"
                name="addressLine2"
                label="Street 2"
                value={values.addressLine2}
                size="small"
                onChange={handleChange}
                error={touched.addressLine2 && Boolean(errors.addressLine2)}
              />
              <FormHelperText>
                {touched.addressLine2 && errors.addressLine2}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={5} lg={4}>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="city"
                name="city"
                label="City"
                value={values.city}
                size="small"
                onChange={handleChange}
                error={touched.city && Boolean(errors.city)}
              />
              <FormHelperText>{touched.city && errors.city}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={3} lg={2}>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="state"
                name="state"
                label="State"
                value={values.state.toLocaleUpperCase()}
                size="small"
                onChange={handleChange}
                error={touched.state && Boolean(errors.state)}
              />
              <FormHelperText>{touched.state && errors.state}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={4} lg={2}>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="postalCode"
                name="postalCode"
                label="Zip"
                value={values.postalCode}
                size="small"
                onChange={handleChange}
                error={touched.postalCode && Boolean(errors.postalCode)}
              />
              <FormHelperText>
                {touched.postalCode && errors.postalCode}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            md={12}
            lg={4}
            justifyContent="right"
            alignContent="baseline"
          >
            <Button
              id="fill-address-button"
              aria-controls={menuOpen ? "fill-address-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleMenuClick}
              endIcon={<KeyboardArrowDownIcon />}
              disabled={isBaseOptionDisabled && isCurrentOptionDisabled}
              sx={{ height: "40px" }}
            >
              <PlaceOutlinedIcon
                sx={{ mr: "4px", mb: "2px", fontSize: "18px" }}
              />
              Use Address
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={(e) => {
                  handleAddressTypeChange("baseAddress");
                  handleMenuClose();
                }}
                disableRipple
                disabled={isBaseOptionDisabled}
              >
                <MapsHomeWorkIcon />
                Base Address
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleAddressTypeChange("currentAddress");
                  handleMenuClose();
                }}
                disableRipple
                disabled={isCurrentOptionDisabled}
              >
                <NearMeIcon />
                Current Address
              </MenuItem>    
              <Divider />
              <MenuItem
                onClick={(e) => {
                  handleAddressTypeChange("manual");
                  handleMenuClose();
                }}
                disableRipple
                disabled={isCurrentOptionDisabled}
            >
              <WrongLocationRoundedIcon />
              Clear Address
            </MenuItem>
            </StyledMenu>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} lg={4}>
         
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default LocationSelector;
