import { string, object, number, Schema } from "yup";

export const ScheduleServiceProviderSchema: Schema<any> = object({
  service: string().required(),
  requestStartWindow: string().required("Start Date is required"),
  requestEndWindow: string().nullable(),
  addressType: string().required(),
  addressLine1: string().required("Street 1 is required"),
  addressLine2: string(),
  city: string().required("City is required"),
  state: string().required("State is required"),
  postalCode: string().required("Zip is required"),
}).defined();
