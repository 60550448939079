import React from "react";
import {
  RequestDto,
  RequestStatusEnum,
} from "../../../api/TrailerPMApi.generated";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { ButtonGroup, Grid, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import EmptyRequestTable from "../../global/Placeholders/EmptyRequestTable";
import VinInfoTooltip from "../Fleet/FleetManagement/VinInfoTooltip";
import { useLocation } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ToCurrency } from "../../../functions/DecimalToCurrency";
import { CustomWidthTooltip } from "../../common/mui/CustomWidthTooltip";

const ProviderRequestsTable = ({
  requests,
  loading,
  handleUpdateRequestStatus,
  handleRedirect,
}: {
  requests: RequestDto[] | undefined;
  loading?: boolean;
  handleUpdateRequestStatus: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    statusId: RequestStatusEnum,
    r: RequestDto,
    type?: string
  ) => void;
  handleRedirect?: any;
}) => {
  const location = useLocation();
  const redirectDisabled = location.state?.redirectDisabled;

  return (
    <>
      {loading ? (
        <EmptyRequestTable loading={loading} />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Request #</TableCell>
                <TableCell align="left">Service Type</TableCell>
                <TableCell align="left">Service By</TableCell>
                <TableCell align="left">Fleet</TableCell>
                <TableCell align="left">VIN</TableCell>
                <TableCell align="left">Vehicle Name</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests?.map((r: RequestDto) => (
                <TableRow
                  key={r.requestNumber}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    <OpenInNewIcon
                      sx={{ cursor: "pointer" }}
                      color="primary"
                      onClick={() =>
                        !redirectDisabled
                          ? handleRedirect(`/requests/${r.requestNumber}`, r)
                          : null
                      }
                    />
                  </TableCell>
                  <TableCell align="left">{r.requestNumber}</TableCell>
                  <TableCell align="left">{r.serviceType}</TableCell>
                  <TableCell align="left">{`${r.serviceByDate?.toLocaleString()}`}</TableCell>
                  <TableCell align="left">{r.fleetName}</TableCell>
                  <TableCell align="left">
                    <CustomWidthTooltip
                      placement="bottom-end"
                      title={<VinInfoTooltip vinInfo={r.vinInfo} />}
                    >
                      <Typography variant="caption" sx={{ cursor: "pointer" }}>
                        {r.vin}
                      </Typography>
                    </CustomWidthTooltip>
                  </TableCell>
                  <TableCell align="left">{r.vehicleName}</TableCell>
                  <TableCell align="left">
                    <Grid item container direction="column">
                      <Grid item>{r.address?.addressLine1}</Grid>
                      <Grid item>
                        {`${r.address?.city}, ${r.address?.state}, ${r.address?.postalCode}`}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="right">{ToCurrency(r?.total)}</TableCell>
                  <TableCell align="left">
                    <ButtonGroup>
                      <Button
                        onClick={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                          handleUpdateRequestStatus(
                            e,
                            RequestStatusEnum.Booked,
                            r
                          );
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        onClick={(e) => {
                          handleUpdateRequestStatus(
                            e,
                            RequestStatusEnum.Declined,
                            r
                          );
                        }}
                      >
                        Decline
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ProviderRequestsTable;
