import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../functions/PageTitleFunction";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import {
  ProviderApi,
  RequestApi,
  GetProviderRequestListDto,
  RequestStatusEnum,
  RequestDto,
} from "../../../api/TrailerPMApi.generated";
import { useUserContext } from "../../../context/UserProvider";
import { Grid, Typography } from "@mui/material";
import ProviderInProgressTable from "./ProviderInProgressTable";
import ProviderRequestsTable from "./ProviderRequestsTable";
import { BillingRequestEnums } from "../../../functions/RequestEnumFunctions";

const ProviderBilling = () => {
  PageTitle(`Billing`);
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const providerApi = new ProviderApi();
  const requestApi = new RequestApi();
  requestApi.token = token;
  providerApi.token = token;

  const [invoicedRequests, setInvoicedRequests] = useState<RequestDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [redirectDisabled, setRedirectDisabled] = useState<boolean>(true);
  const handleRedirect = (route: string) => {
    setRedirectDisabled(true);
    navigate(`${route}`, { state: { redirectDisabled } });
  };

  const getRequests = () => {
    if (currentUser?.serviceProviderId) {
      providerApi
        .getRequestsForServiceProvider(
          new GetProviderRequestListDto({
            providerId: currentUser.serviceProviderId,
            filteredStatuses: BillingRequestEnums(),
          })
        )
        .then((r: RequestDto[]) => {
          setInvoicedRequests(r);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (currentUser != null) {
      getRequests();
    }
  }, [currentUser]);

  return (
    <Grid container>
      <Grid container direction="column" mt={4}>
        <Grid item container direction="column" mt={4}>
          <Grid item>
            <Typography>Invoiced Requests</Typography>
          </Grid>
          <Grid item mt={2}>
            <ProviderInProgressTable
              requests={invoicedRequests}
              loading={loading}
              handleRedirect={handleRedirect}
              handleUpdateRequestStatus={function (
                e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                statusId: RequestStatusEnum,
                r: RequestDto,
                type?: string | undefined
              ): void {
                throw new Error("Function not implemented.");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProviderBilling;
