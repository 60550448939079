import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import {PageTitle} from "../../../../functions/PageTitleFunction";
import {ProviderApi, ServiceProviderDto} from "../../../../api/TrailerPMApi.generated";
import {ColDefMonoTable} from "../../../../interfaces/interfaces";
import CreateProviderModal from "./CreateProviderModal";
import MonoTable from "../../../global/table/MonoTable";
import {useGlobalLoading} from "../../../../context/LoadingContext";
import {ToastAlert} from "../../../global/Toast/ToastAlert";

const AdminProviderPage = () => {
  PageTitle(`Provider Admin`);
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const providerApi = new ProviderApi();
  providerApi.token = token;
  const [providers, setProviders] = useState<ServiceProviderDto[]>([]);
  const { setLoadingData } = useGlobalLoading();
  
  const getProviderList = async () => {
    setLoadingData(true);
    await providerApi.getProviders()
        .then((providersList) => {
          setLoadingData(false)
          setProviders(providersList)
        })
        .catch(err => {
          setLoadingData(false);
          ToastAlert("error", `Error gathering providers: ${err}`)
        });
  };
  const updateProviders = async () => {
    getProviderList();
  };
  
  const columns: ColDefMonoTable = [
    {
      name: "name",
      label: "Company Name"
    },
    {
      name: "address",
      label: "Address"
    },
    {
      name: "contactPhone",
      label: "Contact Phone"
    },
    {
      name: "contactEmail",
      label: "Email"
    }
  ]

  useEffect(() => {
    getProviderList();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} my={3}>
        <CreateProviderModal
          updateProviders={getProviderList}
        ></CreateProviderModal>
      </Grid>
      <Grid item xs={12}>
        <MonoTable columns={columns} rows={providers} title={"Provider Admin"} />
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

export default AdminProviderPage;
