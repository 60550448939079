import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  FleetAssetDto,
  VinInfoDto,
} from "../../../../api/TrailerPMApi.generated";
import { Grid, Typography } from "@mui/material";

export default function VinInfoTooltip({
  vinInfo,
  margin = 1,
}: {
  vinInfo: VinInfoDto | undefined;
  margin?: number;
}) {
  if (vinInfo === undefined || !vinInfo.hasVinDetails) {
    return (
      <Grid container sx={{ m: margin }} rowSpacing={0}>
        <Grid item xs={12}>
          Invalid VIN
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container sx={{ m: margin }} rowSpacing={1}>
        <Grid item xs={4}>
          <Typography>Make</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{vinInfo.make}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography>Year</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{vinInfo.modelYear}</Typography>
        </Grid>

        {vinInfo.vehicleType === "TRAILER" && (
          <Grid item xs={4}>
            <Typography>Trailer Type</Typography>
          </Grid>
        )}
        {vinInfo.vehicleType === "TRAILER" && (
          <Grid item xs={8}>
            <Typography>{vinInfo.trailerType}</Typography>
          </Grid>
        )}

        {vinInfo.vehicleType === "TRAILER" && (
          <Grid item xs={4}>
            <Typography>Trailer Length</Typography>
          </Grid>
        )}
        {vinInfo.vehicleType === "TRAILER" && (
          <Grid item xs={8}>
            <Typography>{vinInfo.trailerLength}</Typography>
          </Grid>
        )}

        {vinInfo.vehicleType === "TRAILER" && (
          <Grid item xs={4}>
            <Typography>Body Type</Typography>
          </Grid>
        )}
        {vinInfo.vehicleType === "TRAILER" && (
          <Grid item xs={8}>
            <Typography>{vinInfo.trailerBodyType}</Typography>
          </Grid>
        )}
        {/* Non-trailer details */}
        {vinInfo.vehicleType !== "TRAILER" && (
          <Grid item xs={4}>
            <Typography>Vehicle Type</Typography>
          </Grid>
        )}
        {vinInfo.vehicleType !== "TRAILER" && (
          <Grid item xs={8}>
            <Typography>{vinInfo.vehicleType}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}
