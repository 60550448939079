import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import { useUserContext } from "../../../../context/UserProvider";
import {
  CustomOfferingDto,
  ProviderApi,
  ServiceApi,
  ServiceItemDto,
} from "../../../../api/TrailerPMApi.generated";
import { TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useGlobalLoading } from "../../../../context/LoadingContext";
import EmptyServicePricingTable from "../../../global/Placeholders/EmptyServicePricingTable";
import Box from "@mui/material/Box";

const ServiceRow = ({
  service,
  providerId,
  updateOffering,
}: {
  service: ServiceItemDto;
  providerId: number | null | undefined;
  updateOffering: (
    serviceId: number,
    time: DateTime | null,
    rate: string
  ) => void;
}) => {
  const [defaultTime] = useState<DateTime>(
    DateTime.now()
      .startOf("day")
      .plus({ hours: service.defaultHours, minute: service.defaultMinutes })
  );

  const [overrideTime, setOverrideTime] = useState<DateTime | null>(
    service.overrideHours
      ? DateTime.now()
          .startOf("day")
          .plus({
            hours: service.overrideHours ?? 0,
            minute: service.overrideMinutes ?? 0,
          })
      : null
  );

  const [rate, setRate] = useState<string>(
    service.overrideRate?.toFixed(2) ?? ""
  );

  const saveTime = (time: DateTime | null) => {
    if (time === null) {
      setOverrideTime(null);
    } else {
      setOverrideTime(time);
    }
  };

  const setOverrideRate = (e: any) => {
    let x = parseFloat(e.target.value);
    if (isNaN(x)) {
      setRate("");
    } else {
      setRate(x.toString());
    }
  };

  return (
    <TableRow>
      <TableCell>{service.display} </TableCell>
      <TableCell>
        <TimePicker
          value={overrideTime}
          ampm={false}
          onChange={(e) => saveTime(e)}
          slotProps={{
            textField: {
              placeholder: defaultTime.toLocaleString(DateTime.TIME_24_SIMPLE),
            },
          }}
        ></TimePicker>
      </TableCell>
      <TableCell>
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            type="number"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Amount"
            placeholder={service.defaultRate?.toFixed(2)}
            value={rate}
            onChange={(e) => setOverrideRate(e)}
            onBlur={(e) => {
              if (rate !== "") {
                setRate((+rate).toFixed(2));
              }
            }}
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          onClick={(e) =>
            updateOffering(service.serviceId ?? 0, overrideTime, rate)
          }
        >
          Update
        </Button>
      </TableCell>
    </TableRow>
  );
};

const ProviderServices = () => {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const serviceApi = new ServiceApi();
  const providerApi = new ProviderApi();
  const { currentUser } = useUserContext();
  const { setGlobalLoading } = useGlobalLoading();

  const [providerServices, setProviderServices] = useState<ServiceItemDto[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    getProviderServices();
  }, [currentUser]);

  const getProviderServices = () => {
    if (currentUser?.serviceProviderId) {
      serviceApi.token = token;
      serviceApi
        .getServicesConfig(currentUser.serviceProviderId)

        .catch((error) => {
          console.log(error);
        })
        .then((data) => {
          if (data) {
            setProviderServices(data);
          }
        });
    }
  };

  const updateOffering = (
    serviceId: number,
    time: DateTime | null,
    rate: string
  ) => {
    setGlobalLoading(true);
    serviceApi.token = token;

    serviceApi
      .updateProviderServiceOffering(
        new CustomOfferingDto({
          providerId: currentUser?.serviceProviderId ?? undefined,
          serviceId: serviceId,
          hours: time ? time.hour + time.minute / 60 : null,
          rate: rate !== "" ? parseFloat(rate) : null,
        })
      )
      .catch((e) => {
        setGlobalLoading(false);
      })
      .then((e) => {
        if (e) {
          const index = providerServices.findIndex(
            (s) => s.serviceId === e.serviceId
          );
          providerServices[index] = e;
        }
        setGlobalLoading(false);
      });
  };

  return (
    <Grid container>
      <Grid item container xs={12}>
        { providerServices.length > 0 ? (
            <TableContainer>
              <Table sx={{}}>
                <TableHead>
                  <TableRow>
                    <TableCell>Service</TableCell>
                    <TableCell>Service Hours</TableCell>
                    <TableCell>Service Rate</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {providerServices.map((s, index) => (
                      <ServiceRow
                          service={s}
                          providerId={currentUser?.serviceProviderId}
                          updateOffering={updateOffering}
                          key={"service_" + s.serviceId}
                      />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        ) : (<EmptyServicePricingTable loading={loading} />)}
      </Grid>
    </Grid>
  );
};

export default ProviderServices;
