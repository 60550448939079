import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";
import { Security } from "@okta/okta-react";
import oktaConfig from "./config/oktaConfig/oktaConfig";
import Layout from "./components/global/layout/Layout";
import { UserProvider } from "./context/UserProvider";
import { ThemeProvider } from "@mui/material/styles";
import AppRoutes from "./components/Routes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import StatesProvider from "./context/StatesProvider";
import { theme } from "./components/global/theme/Theme";
import { LoadingProvider } from "./context/LoadingContext";
const App = () => {
  const oktaAuth = new OktaAuth(oktaConfig.oidc);

  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UserProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <StatesProvider>
            <LoadingProvider>
              <ThemeProvider theme={theme}>
                <Layout theme={theme}>
                  <AppRoutes />
                </Layout>
              </ThemeProvider>
            </LoadingProvider>
          </StatesProvider>
        </LocalizationProvider>
      </UserProvider>
    </Security>
  );
};

export default App;
