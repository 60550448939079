import { Typography } from "@mui/material";
import { AddressDto } from "../../../api/TrailerPMApi.generated";
import React from "react";

export default function AddressDtoTableDisplay({
  address,
}: {
  address: AddressDto | undefined;
}) {
  if (!address) {
    return null;
  } else {
    return (
      <React.Fragment>
        <Typography variant="inherit">{address.addressLine1}</Typography>
        {address.addressLine2 && (
          <Typography variant="inherit">{address.addressLine1}</Typography>
        )}
        <Typography variant="inherit">
          {address.city}, {address.state} {address.postalCode}
        </Typography>
      </React.Fragment>
    );
  }
}
