import React, { useState, MouseEvent, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, Grid } from "@mui/material";
import {
  FileParameter,
  FleetAssetApi,
  FleetAssetDto,
} from "../../../../api/TrailerPMApi.generated";
import { useOktaAuth } from "@okta/okta-react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

export default function ManageFleetModal({ fleet, updateFleet, getFleet }: any) {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const fleetAssetApi = new FleetAssetApi();
  const [open, setOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    getFleet();
    setOpen(false)
  };

  const [file, setFile] = useState<File | null>(null);
  const setUpload = () => {
    setUploadOpen(!uploadOpen);
  };
  //import

  // let files: File = new Array<any>();
  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
    console.log(e.target.files);
  };

  const uploadCsv = async () => {
    setLoading(true);
    fleetAssetApi.token = token;
    if (file) {
      await fleetAssetApi
        .uploadAssetsCsv({ data: file, fileName: file.name })
        .then((result) => {
          updateFleet(result);
          setOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  //export
  const exportToCsv = (e: MouseEvent) => {
    fleetAssetApi.token = token;
    fleetAssetApi.getAssetsCsv().then((result) => {
      downloadFile({
        data: result,
        fileName: "Assets.csv",
        fileType: "text/csv",
      });
    });

    e.preventDefault();
  };

  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    a.click();
    a.remove();
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Manage Fleet
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Manage Fleet
          </Typography>
          <Grid container mt={2}>
            <Grid item container justifyContent="center" xs={6}>
              <Button onClick={setUpload} disabled={loading}>
                Upload Fleet Assets
              </Button>
            </Grid>
            <Grid item container justifyContent="center" xs={6}>
              <Button onClick={exportToCsv} disabled={loading}>
                {fleet?.length > 0
                  ? "Download Fleet Assets"
                  : "Download Template"}
              </Button>
            </Grid>
            {uploadOpen && (
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                xs={12}
                mt={2}
              >
                <form style={{ display: "contents" }}>
                  <input
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                    disabled={loading}
                  />
                  <Box sx={{ m: 1, position: "relative" }}>
                    <Button
                      variant="contained"
                      onClick={uploadCsv}
                      disabled={loading}
                    >
                      Submit
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </form>
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
