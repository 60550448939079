import React from 'react';
import Chip from "@mui/material/Chip";

const StatusChip = ({ status }: any) => {
    const handleChips = (s: any = "outlined") => {
        switch (s) {
            case 'Accepted':
               return (<Chip variant="Accepted" label="Accepted" />)
            case 'Booked':
                return (<Chip variant="Booked" label="Booked" />)
            case 'Completed':
                return (<Chip variant="Completed" label="Completed" />)
            case 'Declined':
                return (<Chip variant="Declined" label="Declined" />)
            case 'Estimate':
                return (<Chip variant="Estimate" label="Estimate" />)
            case 'Invoicing':
                return (<Chip variant="Invoicing" label="Invoicing" />)
            case 'In Progress':
                return (<Chip variant="In Progress" label="In Progress" />)
            case 'Requested':
                return (<Chip variant="Requested" label="Requested" />)
            default:
                return (<div></div>)
        }
    }
    
    return (
        <div>
            {handleChips(status?.name)}
        </div>
    );
};

export default StatusChip;
