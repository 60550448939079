import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
  RequestDto,
  RequestStatusEnum,
} from "../../../api/TrailerPMApi.generated";
import VinInfoTooltip from "../Fleet/FleetManagement/VinInfoTooltip";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import EmptyRequestTable from "../../global/Placeholders/EmptyRequestTable";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StatusChip from "../../../functions/StatusChip";
import { ToCurrency } from "../../../functions/DecimalToCurrency";

const ProviderInProgressTable = ({
  requests,
  loading,
  handleUpdateRequestStatus,
  handleRedirect,
}: {
  requests: RequestDto[] | undefined;
  loading?: boolean;
  handleUpdateRequestStatus: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    statusId: RequestStatusEnum,
    r: RequestDto,
    type?: string
  ) => void;
  handleRedirect?: any;
}) => {
  const location = useLocation();
  const redirectDisabled = location.state?.redirectDisabled;

  return (
    <>
      {loading ? (
        <EmptyRequestTable loading={loading} />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Request #</TableCell>
                <TableCell align="left">Service Type</TableCell>
                <TableCell align="left">Service By</TableCell>
                <TableCell align="left">Fleet</TableCell>
                <TableCell align="left">VIN</TableCell>
                <TableCell align="left">Vehicle Name</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests?.map((r: RequestDto) => (
                <TableRow
                  key={r.requestNumber}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <OpenInNewIcon
                      sx={{ cursor: "pointer" }}
                      color="primary"
                      onClick={() =>
                        !redirectDisabled
                          ? handleRedirect(`/requests/${r.requestNumber}`, r)
                          : null
                      }
                    />
                  </TableCell>
                  <TableCell align="left">{r.requestNumber}</TableCell>
                  <TableCell align="left">{r.serviceType}</TableCell>
                  <TableCell align="left">{`${r.serviceByDate?.toLocaleString()}`}</TableCell>
                  <TableCell align="left">{r.fleetName}</TableCell>
                  <TableCell align="left">
                    <Tooltip
                      placement="bottom-end"
                      title={<VinInfoTooltip vinInfo={r.vinInfo} />}
                    >
                      <Typography variant="caption" sx={{ cursor: "pointer" }}>
                        {r.vin}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">{r.vehicleName}</TableCell>
                  <TableCell align="left">
                    <Grid item container direction="column">
                      <Grid item>{r.address?.addressLine1}</Grid>
                      <Grid item>
                        {`${r.address?.city}, ${r.address?.state}, ${r.address?.postalCode}`}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="right">{ToCurrency(r?.total)}</TableCell>
                  <TableCell align="left">
                    <StatusChip status={r.status} />
                  </TableCell>
                  {r.status?.name === "Booked" ? (
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        onClick={(e) =>
                          handleUpdateRequestStatus(
                            e,
                            RequestStatusEnum.InProgress,
                            r,
                            "start"
                          )
                        }
                      >
                        Start Service
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell align="left"></TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ProviderInProgressTable;
