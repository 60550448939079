import React, { useEffect, useState } from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useOktaAuth } from "@okta/okta-react";
import {
  ServiceItemDto,
  ProviderApi,
  ServiceApi,
  ServiceProviderDto,
} from "../../../../api/TrailerPMApi.generated";
import Button from "@mui/material/Button";
import { DateTime } from "luxon";
import { TimePicker } from "@mui/x-date-pickers";
import { number } from "yup";
import { PageTitle } from "../../../../functions/PageTitleFunction";

const TimeConfig = ({ row, edit }: { row: ServiceItemDto; edit: boolean }) => {
  const [time, setTime] = useState<DateTime>(
    DateTime.now()
      .startOf("day")
      .plus({ hours: row.defaultHours, minute: row.defaultMinutes })
  );

  const saveTime = (time: DateTime | null) => {
    if (time === null) {
      return;
    }
    row.defaultHours = time.hour;
    row.defaultMinutes = time.minute;
  };

  return (
    <TimePicker
      value={time}
      disabled={!edit}
      ampm={false}
      onChange={(e) => saveTime(e)}
    ></TimePicker>
  );
};

const AdminConfig = () => {
  PageTitle(`Configuration`);
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const serviceApi = new ServiceApi();
  const [edit, setEdit] = useState<boolean>(false);
  const [services, setServices] = useState<ServiceItemDto[]>([]);
  const [defaultRate, setDefaultRate] = useState<number | undefined>(0.0);

  //get services
  const getServices = async () => {
    serviceApi.token = token;
    return await serviceApi.getServicesConfig();
  };

  useEffect(() => {
    getServices().then((results) => {
      setServices(results);
      setDefaultRate(results[0].defaultRate);
    });
  }, []);

  //Update Default hours
  const updateDefaultHours = async (index: number) => {
    let service = services[index];
    serviceApi.token = token;
    return await serviceApi.updateDefaultHoursPerService(service).then(() => {
      setEdit(false);
    });
  };

  // Update Default Rate
  const saveRate = async () => {
    serviceApi.token = token;
    return await serviceApi.updateDefaultRate(defaultRate).then(() => []);
  };

  const setRate = (e: any) => {
    let x = parseFloat(e.target.value);
    if (isNaN(x)) {
      setDefaultRate(0);
    } else {
      setDefaultRate(x);
    }
  };
  return (
    <Grid container>
      <Grid container my={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Default Hours Per Service</Typography>
        </Grid>
        <Grid item container xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell align="right">Hours</TableCell>
                  <TableCell align="right">Rate</TableCell>
                  <TableCell align="right">Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {services?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row?.display}
                    </TableCell>
                    <TableCell align="right">
                      <TimeConfig row={row} edit={edit}></TimeConfig>
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                          disabled={!edit}
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          type="number"
                          onChange={(e) => {
                            setRate(e);
                          }}
                          value={defaultRate}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">$</InputAdornment>
                            ),
                          }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {edit ? (
                        <Button onClick={() => updateDefaultHours(index)}>
                          {" "}
                          Save{" "}
                        </Button>
                      ) : (
                        <Button onClick={() => setEdit(true)}>Edit</Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminConfig;
