import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../../../context/UserProvider";

const LoginPage = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { currentUser } = useUserContext();

  const login = async () => {
    console.log("signing in....");
    await oktaAuth.signInWithRedirect();
  };

  if (authState?.isAuthenticated && currentUser != null) {
    console.log("current user", currentUser);
    switch (currentUser.userType) {
      case "FleetUser":
        return <Navigate to="/management" replace />;
      case "ServiceProvider":
        return <Navigate to="/requests" replace />;
      case "Admin":
        return <Navigate to="/admin/fleets" replace />;
      case "User":
      default:
        return <Navigate to="/404" replace />;
    }
  } else {
    login().then(() => console.log("IN"));
    return null;
  }
};

export default LoginPage;
