export const ToCurrency = (number: number | null | undefined) => {
  if (number === null || number === undefined) {
    return "";
  }

  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
