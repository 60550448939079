import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { LinearProgress } from "@mui/material";

const EmptyServicePricingTable = ({ loading }: { loading?: boolean }) => {
    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="left">Service</TableCell>
                            <TableCell align="right">Service Hours</TableCell>
                            <TableCell align="right">Service Rate</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                {loading ? <LinearProgress /> : <TableBody />}
            </TableContainer>
        </>
    );
};

export default EmptyServicePricingTable;
