import { createTheme } from "@mui/material/styles";
import { alpha } from "@mui/material";

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    Accepted: true;
    Address: true;
    Booked: true;
    Completed: true;
    Declined: true;
    Default: true;
    Estimate: true;
    Invoicing: true;
    "In Progress": true;
    Requested: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    FormTitle: true;
  }
}

declare module "@mui/material/styles" {
  interface Components {
    [key: string]: any;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#3484f0",
    },
    secondary: {
      main: "#c4dbfa",
    },
    info: {
      main: "#f7f7f7f7",
    },
    warning: {
      main: "#21971C",
    },
    background: { default: "" },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "FormTitle",
          },
          style: {
            fontSize: "10pt",
            color: "#c7c7c7",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 350,
          },
        },
      ],
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#fff",
          fontFamily: "Poppins",
          fontWeight: 300,
          padding: "10px",
        },
      },
      defaultProps: {
        size: "small",
      },
      variants: [
        {
          props: {
            variant: "Accepted",
          },
          style: {
            border: "1px solid #21A326",
            background: alpha("#21A326", 0.4),
          },
        },
        {
          props: {
            variant: "Booked",
          },
          style: {
            border: "1px solid #100BF3",
            backgroundColor: alpha("#100BF3", 0.4),
          },
        },
        {
          props: {
            variant: "Completed",
          },
          style: {
            border: "1px solid #52B523",
            background: alpha("#52B523", 0.4),
          },
        },
        {
          props: {
            variant: "Declined",
          },
          style: {
            border: "1px solid #EF0808",
            background: alpha("#EF0808", 0.4),
          },
        },
        {
          props: {
            variant: "Estimate",
          },
          style: {
            border: "1px solid #ED9108",
            background: alpha("#ED9108", 0.4),
          },
        },
        {
          props: {
            variant: "In Progress",
          },
          style: {
            border: "1px solid #8D0EBA",
            background: alpha("#8D0EBA", 0.4),
          },
        },
        {
          props: {
            variant: "Invoicing",
          },
          style: {
            border: "1px solid #C2C2C2",
            background: alpha("#C2C2C2", 0.4),
            color: "black",
          },
        },
        {
          props: {
            variant: "Requested",
          },
          style: {
            border: "1px solid #1CBDF0",
            background: alpha("#1CBDF0", 0.4),
          },
        },
        {
          props: {
            variant: "Default",
          },
          style: {
            border: "1px solid #8f8d8d",
            background: alpha("#8f8d8d", 0.4),
          },
        },
        {
          props: {
            variant: "Address",
          },
          style: {
            border: "1px solid #8f8d8d",
            background: "none",
            color: "black",
            fontSize: "12pt",
            padding: "15px",
          },
        },
      ],
    },
  },
});
