const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "{clientId}";
const ISSUER =
  process.env.REACT_APP_ISSUER || "https://{yourOktaDomain}.com/oauth2/default";
const BASENAME = process.env.PUBLIC_URL || "";
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

const oktaConfig = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
  },
  resourceServer: {
    messagesUrl: "http://localhost:8000/api/messages",
  },
  app: {
    basename: BASENAME,
  },
};

export default oktaConfig;
