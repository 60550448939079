import { Routes, Route, To, useNavigate } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./SecureRoute";
import Loading from "./Loading";
import LoginPage from "./pages/Login/Login";
import FleetBilling from "./pages/Fleet/Billing/FleetBilling";
import PageNotFound from "./pages/404/PageNotFound";
import { useUserContext } from "../context/UserProvider";
import ProviderConfig from "./pages/ServiceProvider/ProviderConfig/ProviderConfig";
import ProviderRequests from "./pages/ServiceProvider/ProviderRequests";
import ProviderBilling from "./pages/ServiceProvider/ProviderBilling";
import FleetManagementPage from "./pages/Fleet/FleetManagement/FleetManagementPage";
import ServiceProvidersPage from "./pages/Fleet/ServiceProviders/ServiceProvidersPage";
import FleetServiceRequestsPage from "./pages/Fleet/ServiceRequests/FleetServiceRequestsPage";
import AdminConfig from "./pages/Admin/Config/AdminConfig";
import RequestDetailPage from "./pages/Request/RequestDetails/RequestDetailPage";
import AdminFleetsPage from "./pages/Admin/Fleets/AdminFleetPage";
import AdminProviderPage from "./pages/Admin/ServiceProviders/AdminProviderPage";

const AppRoutes = () => {
  const { currentUser } = useUserContext();

  const navigate = useNavigate();

  const redirectPage = (route: To) => {
    navigate(route);
  };

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="login/callback"
        element={<LoginCallback loadingElement={<Loading />} />}
      />

      <Route path="" element={<RequiredAuth />}>
        <Route path="management" element={<FleetManagementPage />} />
        <Route path="providers" element={<ServiceProvidersPage />} />
        <Route path="billing">
          <Route path="fleet" element={<FleetBilling />} />
          <Route path="provider" element={<ProviderBilling />} />
        </Route>
        <Route path="services" element={<ProviderConfig />} />
        <Route path="requests">
          <Route path="" element={<ProviderRequests />} />
          <Route path=":requestNumber" element={<RequestDetailPage />} />
        </Route>
        <Route path="serviceRequests" element={<FleetServiceRequestsPage />} />
        <Route path="admin">
          <Route path="fleets" element={<AdminFleetsPage />} />
          <Route path="providers" element={<AdminProviderPage />} />
          <Route path="config" element={<AdminConfig />} />
        </Route>
      </Route>
      <Route
        path="/404"
        element={<PageNotFound redirectPage={redirectPage} />}
      />
      {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
    </Routes>
  );
};

export default AppRoutes;
