import React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Collapse, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

const AdminTableSubRow = ({ open }: any) => {
    console.log(open)
    return (
        <TableRow>
            <TableCell
                style={{ paddingBottom: 0, paddingTop: 0, background: "#eeeee4" }}
                colSpan={8}
            >
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ m: 1 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Fleet Info
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};


export default AdminTableSubRow;
