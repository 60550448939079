import * as React from "react";
import { Theme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/LTlogo.png";
import UserMenu from "./UserMenu";
import FleetSideNavigation from "../../pages/Fleet/FleetSideNavigation";
import { Container } from "@mui/material";
import { useUserContext } from "../../../context/UserProvider";
import ProviderSideNavigation from "../../pages/ServiceProvider/ProviderSideNavigation";
import AdminSideNavigation from "../../pages/Admin/AdminSideNavigation";

const drawerWidth = 250;

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => prop !== "open",
})(({ theme, open }: any) => {
  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  };
});

export default function MiniDrawer({ children, theme }: any) {
  const { currentUser } = useUserContext();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const redirectPage = (route: string, replace: boolean = false) => {
    navigate(route, { replace: replace });
  };

  const redirectToUserDefault = () => {
    switch (currentUser?.userType) {
      case "FleetUser":
        redirectPage("/management");
        break;
      case "ServiceProvider":
        redirectPage("/requests");
        break;
      case "Admin":
        redirectPage("/admin/fleets");
        break;
      default:
        redirectPage("/");
        break;
    }
  };

  const renderNavLinks = (userType: string) => {
    switch (userType) {
      case "Admin":
        return (
          <AdminSideNavigation redirectPage={redirectPage} sideNavOpen={open} />
        );
      case "FleetUser":
        return (
          <FleetSideNavigation redirectPage={redirectPage} sideNavOpen={open} />
        );
      case "ServiceProvider":
        return (
          <ProviderSideNavigation
            redirectPage={redirectPage}
            sideNavOpen={open}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        position: "relative",
        "& .MuiDrawer-paper": {
          overflow: "visible",
          backgroundColor: theme.palette.info.main,
        },
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          right: -15,
          top: "50%",
          backgroundColor: "white",
          ":hover": { backgroundColor: theme.palette.secondary.main },
          border: 1,
          borderColor: "grey.500",
        }}
        size="small"
        onClick={open ? handleDrawerClose : handleDrawerOpen}
      >
        {open ? (
          <ChevronLeftIcon fontSize="small" />
        ) : (
          <ChevronRightIcon fontSize="small" />
        )}
      </IconButton>

      <Divider />
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <Container
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2,
              alignItems: "center",
              display: "flex",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : "auto",
                justifyContent: "center",
                my: "12px",
              }}
            >
              {" "}
              <img
                src={logo}
                height={30}
                width={30}
                alt={"logo"}
                onClick={() => {
                  redirectToUserDefault();
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ display: open ? "block" : "none", ml: open ? 2 : 0 }}
              onClick={() => {
                redirectToUserDefault();
              }}
            >
              <Typography variant="h6">TrailerPM</Typography>
            </ListItemText>
            <ListItemText
              sx={{ display: open ? "block" : "none", ml: open ? 2 : 0 }}
            >
              <UserMenu theme={theme} />
            </ListItemText>
          </Container>
        </ListItem>
        <Divider />
        {renderNavLinks(currentUser?.userType ?? "")}
      </List>
    </Drawer>
  );
}
