import React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Collapse, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {ToCurrency} from "../../../../../functions/DecimalToCurrency";
import StatusChip from "../../../../../functions/StatusChip";

const RequestTableSubRow = ({row, open, handleRedirect}: {row: any; open: boolean; handleRedirect: (route: string) => void}) => {
    return (
        <TableRow>
            <TableCell
                style={{ paddingBottom: 0, paddingTop: 0, background: "#eeeee4" }}
                colSpan={8}
            >
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ m: 1 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Requests
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={8}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Date</TableCell>
                                            <TableCell>Provider</TableCell>
                                            <TableCell align="left">Total</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.requestHistory?.map((historyRow: any) => (
                                            <TableRow key={historyRow.requestNumber}>
                                                <TableCell>
                                                    <OpenInNewIcon
                                                        sx={{ cursor: "pointer" }}
                                                        color="primary"
                                                        onClick={() =>
                                                            handleRedirect(
                                                                `/requests/${historyRow.requestNumber}`
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {historyRow.formattedServiceRequestDate}
                                                </TableCell>
                                                <TableCell>{historyRow?.providerName}</TableCell>
                                                <TableCell align="left">
                                                    {ToCurrency(historyRow.total)}
                                                </TableCell>
                                                <TableCell>
                                                    <StatusChip status={historyRow.status} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

export default RequestTableSubRow;
