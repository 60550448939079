import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ServiceProviderCard from "./ServiceProviderCard";
import { Formik, Form } from "formik";
import { ScheduleServiceProviderSchema } from "../../../../validation/ScheduleServiceProviderSchema";
import { DateTime } from "luxon";
import { useOktaAuth } from "@okta/okta-react";
import {
  AddressDto,
  ListItemDto,
  ProviderApi,
  ServiceApi,
  ServiceAvailibilityRequestDto,
  ProviderOfferingDto,
} from "../../../../api/TrailerPMApi.generated";
import { useEffect, useState } from "react";
import LocationSelector from "./Location/LocationSelector";
import { toast } from "react-toastify";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

interface MyFormValues {
  service: string;
  requestStartWindow: DateTime | null;
  requestEndWindow: DateTime | null;
}

interface prefilledAddress {
  baseAddress?: any;
  currentAddress?: any;
}

const initialFormValues = {
  service: 1,
  requestStartWindow: null,
  requestEndWindow: null,
  type: "Request",
  addressType: "Request",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  postalCode: "",
};

export default function BookServiceModal({ asset }: any) {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const providerApi = new ProviderApi();
  const serviceApi = new ServiceApi();
  const [providers, setProviders] = useState<ProviderOfferingDto[]>([]);
  const [services, setServices] = useState<ListItemDto[]>([]);
  const [prefilledAddresses, setPrefilledAddresses] =
    useState<prefilledAddress>();
  const [loading, setLoading] = useState<boolean>(false);

  //Modal open close
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setProviders([]);
    setLoading(false);
    setOpen(false);
  };

  const fetchProviders = async (values: any) => {
    setLoading(true);
    setProviders([]);
    serviceApi.token = token;
    let req = new ServiceAvailibilityRequestDto({
      serviceId: values.service,
      startDate: values.requestStartWindow,
      endDate: values.requestEndWindow ?? values.requestStartWindow,
      location: new AddressDto({
        addressLine1: values.addressLine1,
        addressLine2: "",
        city: values.city,
        state: values.state,
        postalCode: values.postalCode,
        type: "Request",
      }),
    });
    return await serviceApi
      .getAvailableProvidersForService(req)
      .then((providers) => {
        setProviders(providers);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Error getting providers`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const getServices = async () => {
    serviceApi.token = token;
    return await serviceApi.getServicesList();
  };
  useEffect(() => {
    getServices().then((results) => {
      setServices(results);
    });

    setPrefilledAddresses({
      baseAddress: asset?.baseAddress,
      currentAddress: asset?.currentAddress,
    });
  }, []);

  return (
    <div>
      <Button onClick={handleOpen}>Book Service</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={initialFormValues}
            validationSchema={ScheduleServiceProviderSchema}
            onSubmit={(values, actions) => {
              console.log("fetch providers");
              fetchProviders(values);
              actions.setSubmitting(false);
            }}
          >
            {(formik) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                submitForm,
                setValues,
                initialValues,
                setFieldTouched,
                resetForm
              } = formik;
              return (
                <Form>
                  <Grid container rowSpacing={2}>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2"
                    >
                      Schedule Service Provider
                    </Typography>
                    <Grid item container xs={12}>
                      <Grid item xs={6} container justifyContent="flex-start">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label={`Asset: ${asset.name}`}
                            variant="Default"
                            size="small"
                          />
                          <Chip
                            label={`VIN #${asset.vin}`}
                            variant="Default"
                            size="small"
                          />
                        </Stack>
                      </Grid>
                    </Grid>

                    <Grid item container alignContent="center">
                      <Grid item>
                        <Typography variant="FormTitle">Location *</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <LocationSelector
                          values={values}
                          touched={touched}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          setValues={setValues}
                          initialValues={initialValues}
                          prefilledAddresses={prefilledAddresses}
                          setFieldTouched={setFieldTouched}
                          resetForm={resetForm}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container direction="row" spacing={1}>
                      <Grid
                        item
                        sm={6}
                        md={6}
                        lg={3}
                        container
                        direction="column"
                      >
                        <Grid item>
                          <Typography variant="FormTitle">
                            Start Date *
                          </Typography>
                        </Grid>
                        <Grid item sx={{ paddingTop: 1.2 }}>
                          <DatePicker
                            disablePast
                            label="Start Date"
                            value={formik.values.requestStartWindow}
                            onChange={(value) =>
                              formik.setFieldValue("requestStartWindow", value)
                            }
                            slotProps={{
                              textField: {
                                error:
                                  touched.requestStartWindow &&
                                  Boolean(errors.requestStartWindow),
                                helperText:
                                  touched.requestStartWindow &&
                                  errors.requestStartWindow,
                                size: "small",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        md={6}
                        lg={3}
                        container
                        direction="column"
                      >
                        <Grid item>
                          <Typography variant="FormTitle">
                            Required By
                          </Typography>
                        </Grid>
                        <Grid item sx={{ paddingTop: 1.2 }}>
                          <DatePicker
                            disablePast
                            label="Required By"
                            value={formik.values.requestEndWindow}
                            onChange={(value) =>
                              formik.setFieldValue("requestEndWindow", value)
                            }
                            slotProps={{
                              textField: {
                                error:
                                  touched.requestEndWindow &&
                                  Boolean(errors.requestEndWindow),
                                helperText:
                                  touched.requestEndWindow &&
                                  errors.requestEndWindow,
                                size: "small",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        md={8}
                        lg={4}
                        container
                        direction="column"
                      >
                        <Grid item>
                          <Typography variant="FormTitle">
                            Inspection/Service
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ paddingTop: 1.2, minWidth: 200 }}
                          >
                            <InputLabel
                              id="select-label"
                              sx={{ paddingTop: 1 }}
                            >
                              Service
                            </InputLabel>
                            <Select
                              id="service"
                              name="service"
                              value={formik.values.service}
                              label="Service"
                              onChange={handleChange}
                              error={touched.service && Boolean(errors.service)}
                            >
                              {services?.map((service) => (
                                <MenuItem
                                  value={service.value}
                                  key={service.value}
                                >
                                  {service.display}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched.service && errors.service}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        md={4}
                        lg={2}
                        container
                        justifyContent="flex-end"
                        alignContent="baseline"
                      >
                        <Tooltip
                          arrow
                          disableHoverListener={formik.isValid}
                          disableFocusListener={formik.isValid}
                          disableTouchListener={formik.isValid}
                          title={
                            <>
                              <Box sx={{ mr: 2 }}>
                                <ul>
                                  {Object.values(errors).map((err, index) => (
                                    <li key={`error_${index}`}>
                                      <Typography>{err}</Typography>
                                    </li>
                                  ))}
                                </ul>
                              </Box>
                            </>
                          }
                        >
                          <span>
                            <Button
                              type="submit"
                              variant="contained"
                              disabled={!formik.isValid}
                              onClick={submitForm}
                              sx={{ mt: "30px", height: "40px" }}
                            >
                              Search
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  {providers?.length > 0 && (
                    <>
                      <Grid item xs={12} container mt={2}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Search Results
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ height: 250, overflow: "auto" }}>
                        {providers?.map((p, index) => (
                          <ServiceProviderCard
                            key={index}
                            provider={p}
                            requestValues={values}
                            asset={asset}
                            handleClose={handleClose}
                          />
                        ))}
                      </Grid>
                    </>
                  )}
                  {loading && (
                    <Grid item xs={12} mt={2}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Search Results
                      </Typography>
                      <LinearProgress />
                    </Grid>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}
