import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Collapse, Grid, IconButton, Tooltip} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddressDtoTableDisplay from "../../dto-displays/AddressDtoTableDisplay";
import BookServiceModal from "../../../pages/Fleet/FleetManagement/BookServiceModal";
import {ColDefMonoTable} from "../../../../interfaces/interfaces";
import RequestTableSubRow from "./subRow/RequestTableSubRow";
import AdminTableSubRow from "./subRow/AdminTableSubRow";
import {CustomWidthTooltip} from "../../../common/mui/CustomWidthTooltip";
import VinInfoTooltip from "../../../pages/Fleet/FleetManagement/VinInfoTooltip";
import Typography from "@mui/material/Typography";
import StatusChip from "../../../../functions/StatusChip";

const AssetRow = ({row, columns, title}: {row: any; columns: ColDefMonoTable; title: string}) => {
    const [open, setOpen] = useState<boolean>(false);

    const navigate = useNavigate();
    const [redirectDisabled, setRedirectDisabled] = useState<boolean>(true);
    const handleRedirect = (route: string) => {
        setRedirectDisabled(true);
        navigate(`${route}`, {state: {redirectDisabled}});
    };
    
    const handleCellData = (columns: ColDefMonoTable, row: any) => {
        return(
            <>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        disabled={!title.includes("Admin") && !row.requestHistory?.length}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {columns.map((col) => {
                        if (col.name.toLowerCase().includes('address')) {
                            return (
                                <TableCell align="left">
                                    <AddressDtoTableDisplay address={row[col.name]}/>
                                </TableCell>
                            )
                        }
                        
                        if(col.name === "vin"){
                            return (
                                <TableCell sx={{padding: 0}}>
                                    <CustomWidthTooltip
                                        placement="bottom-end"
                                        arrow
                                        title={<VinInfoTooltip vinInfo={row.vinInfo} />}
                                    >
                                        <Typography variant="inherit" sx={{ cursor: "pointer" }}>
                                            {row.vin}
                                        </Typography>
                                    </CustomWidthTooltip>
                                </TableCell>
                                )
                        }
                        return (
                            <TableCell align="left">
                                {col.subName && row[col.name] != null ? (
                                    <Tooltip
                                        placement="bottom-end"
                                        arrow
                                        title={
                                            <>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Typography>Request #</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography>{row[col.name].requestNumber}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography>Provider</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography>{row[col.name].providerName}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography>Status</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <StatusChip status={row[col.name].status}></StatusChip>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                    >
                                        <Typography variant="inherit" sx={{ cursor: "pointer" }}>
                                            {row[`${col.name}`][`${col.subName}`]}
                                        </Typography>
                                    </Tooltip>
                                ) : row[col.name]}
                            </TableCell>
                        )
                    })
                }
                {title.includes("Management") && (<TableCell align="left"><BookServiceModal asset={row} /></TableCell>)}
            </>
        )
    }

    return (
        <>
            <TableRow
                key={row.name}
                sx={{
                    "&:last-child td, &:last-child th": {border: 0},
                    "& > *": {borderBottom: "unset"},
                }}
            >
                {handleCellData(columns, row)}
            </TableRow>
            {title.includes("Admin") ? (<AdminTableSubRow open={open}/>) : (<RequestTableSubRow row={row} open={open} handleRedirect={handleRedirect}/>)}
        </>
    );
}
export default AssetRow;
