export const DescendingComparator = (a: any, b: any, orderBy: any) => {
    if (orderBy === "inspection due date") {
        orderBy = "formattedInspectionDueDate";
    }
    if (orderBy === "next service") {
        orderBy = "nextService['serviceRequestedDate']";
    }
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
        return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
        return 1;
    }
    return 0;
}

export const GetComparator = (order: any, orderBy: any) => {
    return order === "desc"
        ? (a: any, b: any) => DescendingComparator(a, b, orderBy)
        : (a: any, b: any) => -DescendingComparator(a, b, orderBy);
}

export const SortRowInformation = (rowArray: any, comparator: any) => {
    const stabilizedRowArray = rowArray.map((el: any, index: any) => [el, index]);
    stabilizedRowArray.sort((a: any, b: any) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedRowArray.map((el: any) => el[0]);
}