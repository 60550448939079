import React, {useEffect, useState} from "react";
import { PageTitle } from "../../../../functions/PageTitleFunction";
import {FleetAssetApi, FleetAssetDto, ProviderApi, ServiceProviderDto} from "../../../../api/TrailerPMApi.generated";
import {useOktaAuth} from "@okta/okta-react";
import {Grid} from "@mui/material";
import {ColDefMonoTable} from "../../../../interfaces/interfaces";
import MonoTable from "../../../global/table/MonoTable";
import {useGlobalLoading} from "../../../../context/LoadingContext";
import {ToastAlert} from "../../../global/Toast/ToastAlert";

const ServiceProviders = () => {
  PageTitle("Service Providers");
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const providerApi = new ProviderApi();
  providerApi.token = token;
  const [providers, setProviders] = useState<ServiceProviderDto[]>([]);
  const { setLoadingData } = useGlobalLoading();
  
  const getProviderList = async () => {
    await providerApi.getProviders()
        .then((providerList) => {
            setLoadingData(false);
            setProviders(providerList)
        })
        .catch(err => {
            setLoadingData(false);
            ToastAlert("error", `Error gathering providers: ${err}`)
        });
  };

    const columns: ColDefMonoTable = [
        {
            name: "name",
            label: "Company Name"
        },
        {
            name: "address",
            label: "Address"
        },
        {
            name: "contactPhone",
            label: "Contact Phone"
        },
        {
            name: "contactEmail",
            label: "Email"
        }
    ]

    useEffect(() => {
        setLoadingData(true);
        getProviderList();
    },[]);
  

  return (
      <Grid container>
        <Grid item xs={12}>
            <MonoTable columns={columns} rows={providers} title={"Service Providers"} />
        </Grid>
      </Grid>
  );
};

export default ServiceProviders;
