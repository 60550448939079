import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  RequestApi,
  RequestDto,
  RequestStatusEnum,
} from "../../../../api/TrailerPMApi.generated";
import { PageTitle } from "../../../../functions/PageTitleFunction";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { useOktaAuth } from "@okta/okta-react";
import { useUserContext } from "../../../../context/UserProvider";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import VinInfoTooltip from "../../Fleet/FleetManagement/VinInfoTooltip";
import Button from "@mui/material/Button";
import EmptyRequestTable from "../../../global/Placeholders/EmptyRequestTable";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
} from "@mui/material";
import StatusChip from "../../../../functions/StatusChip";
import InspectionFormFileUpload from "../../ServiceProvider/FileUpload/InspectionForm";
import { DownloadFile } from "../../../../functions/DownloadFile";
import { ToCurrency } from "../../../../functions/DecimalToCurrency";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useGlobalLoading } from "../../../../context/LoadingContext";
import MonoTable from "../../../global/table/MonoTable";
import {ColDefMonoTable} from "../../../../interfaces/interfaces";

const InvoiceDownload = ({
  request,
  downloadClick,
}: {
  request: RequestDto;
  downloadClick: (r: RequestDto) => void;
}) => {
  return (
    <React.Fragment>
      <Button variant="text" onClick={() => downloadClick(request)}>
        Download
      </Button>
      <Typography>
        Invoiced by: {request.invoicedByName}, {request.invoicedOnDate}
      </Typography>
    </React.Fragment>
  );
};

const RequestDetailPage = () => {
  const { requestNumber } = useParams();
  const { currentUser } = useUserContext();
  const { oktaAuth } = useOktaAuth();
  const requestApi = new RequestApi();
  requestApi.token = oktaAuth.getAccessToken();
  PageTitle(`Request #${requestNumber}`);

  const [request, setRequest] = useState<RequestDto | null>();

  const [showInvoiceDialog, setShowInvoiceDialog] = useState<boolean>(false);
  const [userType, setUserType] = useState<string>(currentUser?.userType ?? "");
  const { setGlobalLoading, setLoadingData, loadingData } = useGlobalLoading();

  const [showCompleteDialog, setShowCompleteDialog] = useState<boolean>(false);
  const [completeAuthorized, setCompleteAuthorized] = useState<boolean>(false);

  const columns: ColDefMonoTable = [
      { name: "serviceType", label: "Service Type" },
      { name: "formattedServiceByDate", label: "Service By" },
      { name: "providerName", label: "Provider Name" },
      { name: "address", label: "Location" },
      { name: "hours", label: "Hours" },
      { name: "rate", label: "Rate" },
      { name: "total", label: "total" },
  ]
  
  const getRequest = async () => {
    setLoadingData(true)
    await requestApi
    .getRequestByRequestNumber(requestNumber)
    .then((r: RequestDto) => {
        setLoadingData(false)
        setRequest(r);
        setGlobalLoading(false);
    })
    .catch((err) => {
        setLoadingData(false)
        toast.error(`Error fetching request: ${err.toLocaleString()}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setGlobalLoading(false);
    });
  };

  useEffect(() => {
    getRequest()
    setUserType(currentUser?.userType ?? "");
  }, [currentUser]);

  const handleUpdateRequestStatus = (
    statusId: RequestStatusEnum,
    r: RequestDto
  ) => {
    setGlobalLoading(true);
    requestApi
      .updateFleetRequestStatus(r.requestId, statusId)
      .then((r: RequestDto) => {
        setRequest(r);
        toast.success(`Request #${r?.requestNumber} has been updated`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setGlobalLoading(false);
      })
      .catch((err) => {
        toast.error(
          `Could not process update for request #${r.requestNumber}: ${err}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          }
        );
        setGlobalLoading(false);
      });
  };

  const handleInvoiceCreation = (req: RequestDto) => {
    setGlobalLoading(true);
    requestApi
      .createInvoice(req.requestId)
      .then((result) => {
        DownloadFile({
          data: result.fileString,
          fileName: result.fileName,
          fileType: result.mimeType,
        });
        setGlobalLoading(false);
      })
      .catch((err) => {
        setGlobalLoading(false);
        toast.error(
          `Could not create invoice for #${req.requestNumber}: ${err}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          }
        );
      });
  };

  const handleInvoiceClick = () => {
    if (!request?.hasInspectionForm) {
      setShowInvoiceDialog(true);
    } else {
      handleUpdateRequestStatus(RequestStatusEnum.Invoiced, request);
      handleInvoiceCreation(request);
    }
  };

  return (
    <Grid item container spacing={2}>
      <Grid item container direction="column" mb={2}>
        <Grid item>
          <Typography variant="h5">Request #{requestNumber} </Typography>
        </Grid>
        <Grid item mt={1}>
          <StatusChip status={request?.status} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
          {request != null && (<MonoTable columns={columns} rows={[request]} title={"Request Details"} />)}
      </Grid>
      {loadingData ? null : (
        <Grid item xs={12} md={6}>
          <Card sx={{ height: 300 }}>
            <CardContent>
              <Typography
                sx={{
                  fontWeight: 200,
                  fontFamily: "Poppins",
                  fontSize: "16pt",
                  mb: 1,
                }}
              >
                Asset Details - {request?.vehicleName}
              </Typography>
              {request?.vin ? (
                <Typography variant="h5" component="div">
                  VIN: {request?.vin}
                </Typography>
              ) : null}
              <VinInfoTooltip vinInfo={request?.vinInfo} margin={0} />
            </CardContent>
          </Card>
        </Grid>
      )}
      {loadingData ? null : (
        <Grid item xs={12} md={6}>
          <InspectionFormFileUpload request={request} />
        </Grid>
      )}
      <Grid item container xs={12} mt={2}>
        {currentUser?.userType === "ServiceProvider" &&
        request?.status?.name === "Requested" ? (
          <Grid item container direction="row-reverse" spacing={2} mt={1}>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={() => {
                handleUpdateRequestStatus(RequestStatusEnum.Booked, request);
              }}
            >
              Accept
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ ml: 2 }}
              onClick={() => {
                handleUpdateRequestStatus(RequestStatusEnum.Declined, request);
              }}
            >
              Decline
            </Button>
          </Grid>
        ) : null}
        {currentUser?.userType === "ServiceProvider" &&
        request?.status?.name === "Booked" ? (
          <Grid item container direction="row-reverse" spacing={2} mt={1}>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={() =>
                handleUpdateRequestStatus(RequestStatusEnum.InProgress, request)
              }
            >
              Start Service
            </Button>
          </Grid>
        ) : null}
        {currentUser?.userType === "ServiceProvider" &&
        request?.status?.name === "In Progress" ? (
          <Grid item container direction="row-reverse" spacing={2} mt={1}>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={() => {
                handleInvoiceClick();
              }}
            >
              Invoice
            </Button>
          </Grid>
        ) : null}
        {request?.status?.name === "Invoicing" ? (
          <Grid
            item
            container
            direction="row-reverse"
            spacing={2}
            mt={1}
            alignItems="center"
          >
            {currentUser?.userType === "FleetUser" && (
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 2 }}
                onClick={() => {
                  setCompleteAuthorized(false);
                  setShowCompleteDialog(true);
                }}
              >
                Pay Now
              </Button>
            )}
            <InvoiceDownload
              request={request}
              downloadClick={handleInvoiceCreation}
            />
          </Grid>
        ) : null}
        {request?.status?.name === "Completed" ? (
          <Grid
            item
            container
            direction="row-reverse"
            spacing={2}
            mt={1}
            alignItems="center"
          >
            <InvoiceDownload
              request={request}
              downloadClick={handleInvoiceCreation}
            />
          </Grid>
        ) : null}
      </Grid>

      {request && (
        <Dialog
          open={showCompleteDialog}
          onClose={() => {
            setShowCompleteDialog(false);
          }}
          aria-labelledby="complete-dialog-title"
          aria-describedby="complete-dialog-description"
        >
          <DialogTitle id="complete-dialog-title">Finalize Request</DialogTitle>
          <DialogContent>
            <DialogContentText id="complete-dialog-description">
              <Grid container>
                <Grid item xs={12}>
                  <Typography>{`${
                    request.providerName
                  } has invoiced for ${ToCurrency(
                    request.total
                  )}.`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={completeAuthorized}
                          onChange={() =>
                            setCompleteAuthorized(!completeAuthorized)
                          }
                        />
                      }
                      label={`I certify that I have paid for Service Request ${request?.requestNumber}`}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowCompleteDialog(false);
              }}
              variant="text"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleUpdateRequestStatus(RequestStatusEnum.Completed, request);
                setShowCompleteDialog(false);
              }}
              autoFocus
              variant="contained"
              disabled={!completeAuthorized}
            >
              Pay Now
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog
        open={showInvoiceDialog}
        onClose={() => setShowInvoiceDialog(false)}
        aria-labelledby="invoice-dialog-title"
        aria-describedby="invoice-dialog-description"
      >
        <DialogTitle id="invoice-dialog-title">
          Annual Inspection Form Required
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="invoice-dialog-description">
            <span>
              {
                "A Completed Annual Inspection Form is required to Invoice this request."
              }{" "}
              <br />
              {"Blank inspection forms can be downloaded "}
              <a
                href="/files/Annual_Inspection_Report.pdf"
                target="_blank"
                download
              >
                here
              </a>
              {"."}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInvoiceDialog(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default RequestDetailPage;
