import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../../functions/PageTitleFunction";
import { useOktaAuth } from "@okta/okta-react";
import {
  RequestApi,
  RequestDto,
  GetFleetRequestListDto,
} from "../../../../api/TrailerPMApi.generated";
import { useUserContext } from "../../../../context/UserProvider";
import {
  BillingRequestEnums,
  NonBillingRequestEnums,
} from "../../../../functions/RequestEnumFunctions";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import FleetServiceRequestsTable from "../ServiceRequests/FleetServiceRequestsTable";

const FleetBilling = () => {
  PageTitle("Billing");
  const { currentUser } = useUserContext();
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const requestApi = new RequestApi();
  const [requests, setRequests] = useState<RequestDto[]>([]);

  const navigate = useNavigate();
  const [redirectDisabled, setRedirectDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true)
  const handleRedirect = (route: string) => {
    setRedirectDisabled(true);
    navigate(`${route}`, { state: { redirectDisabled } });
  };
  
  const fetchData = async () => {
    setLoading(true)
    requestApi.token = token;
    if (currentUser?.fleetId) {
      await requestApi.getFleetRequests(
          new GetFleetRequestListDto({
            fleetId: currentUser.fleetId,
            filteredStatuses: BillingRequestEnums(),
          })
      ).then((requestList) => {
        setLoading(false)
        setRequests(requestList)
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container direction="column" mt={5}>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5">Invoiced Requests</Typography>
        </Grid>
      </Grid>
      <Grid item mt={2}>
        <FleetServiceRequestsTable
          requests={requests}
          handleRedirect={handleRedirect}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default FleetBilling;
