import React, { useEffect, useState } from "react";
import {
  FleetAssetApi,
  FleetAssetDto,
} from "../../../../api/TrailerPMApi.generated";
import { useOktaAuth } from "@okta/okta-react";
import ManageFleetModal from "./ManageFleetModal";
import { Grid } from "@mui/material";
import { PageTitle } from "../../../../functions/PageTitleFunction";
import { useGlobalLoading } from "../../../../context/LoadingContext";
import {ColDefMonoTable} from "../../../../interfaces/interfaces";
import MonoTable from "../../../global/table/MonoTable";
import {ToastAlert} from "../../../global/Toast/ToastAlert";

const FleetManagement = () => {
  PageTitle("Fleet Management");

  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const fleetAssetApi = new FleetAssetApi();
  fleetAssetApi.token = token;

  const { setLoadingData } = useGlobalLoading();

  const [fleet, setFleet] = useState<FleetAssetDto[]>([]);
  const getFleet = async () => {
    setLoadingData(true)
    await fleetAssetApi.getAssets()
        .then((fleetAssetList) => {
          setLoadingData(false)
          setFleet(fleetAssetList)
        })
        .catch(err => {
          setLoadingData(false)
          ToastAlert("error", `Error gathering fleet assets: ${err}`)
        });
  };

  const columns: ColDefMonoTable = [
    {
      name: "name",
      label: "Name"
    },
    {
      name: "vin",
      label: "VIN"
    },
    {
      name: "baseAddress",
      label: "Base Address"
    },
    {
      name: "currentAddress",
      label: "Current Address"
    },
    {
      name: "formattedInspectionDueDate",
      label: "Inspection Due Date"
    },
    {
      name: "nextService",
      subName: "formattedServiceRequestDate",
      label: "Next Service"
    }
  ]
  
  useEffect(() => {
      getFleet();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} my={3}>
        <ManageFleetModal updateFleet={setFleet} fleet={fleet} />
      </Grid>
      <Grid item xs={12}>
        <MonoTable columns={columns} rows={fleet} title={"Fleet Management"} />
      </Grid>
    </Grid>
  );
};

export default FleetManagement;
