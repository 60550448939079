import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { CreateFleetProviderSchema } from "../../../../../validation/CreateFleetProviderSchema";
import Modal from "@mui/material/Modal";
import {
  FleetDto,
  FleetManagerDto,
} from "../../../../../api/TrailerPMApi.generated";
import { ToastAlert } from "../../../../global/Toast/ToastAlert";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const initialValues = {
  fleetName: "",
  fleetManagerFirstName: "",
  fleetManagerLastName: "",
  fleetManagerEmail: "",
};
const AdminFleetCreationForm = ({
  getFleets,
  fleetApi,
}: {
  getFleets: () => void;
  fleetApi: any;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  const saveFleet = async (values: any) => {
    setLoading(true);
    let req = new FleetDto({
      fleetName: values.fleetName,
      fleetManager: new FleetManagerDto({
        firstName: values.fleetManagerFirstName,
        lastName: values.fleetManagerLastName,
        email: values.fleetManagerEmail,
      }),
    });
    await fleetApi
      .createFleet(req)
      .then((fleet: FleetDto) => {
        setLoading(false);
        ToastAlert("success", `Successfully created fleet: ${fleet.fleetName}`);
        getFleets();
        handleClose();
      })
      .catch((err: any) => {
        setLoading(false);
        ToastAlert("error", `Error creating fleet: ${err}`);
      });
  };

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        Create Fleet
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component={Paper} elevation={3} sx={style}>
          <Formik
            initialValues={initialValues}
            validationSchema={CreateFleetProviderSchema}
            onSubmit={(values) => {
              saveFleet(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              dirty,
              isValid,
              handleChange,
            }) => (
              <Form>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  sx={{ padding: "10px" }}
                >
                  Fleet Quick Add
                </Typography>
                <Grid container spacing={2} sx={{ padding: "10px" }}>
                  <Grid item container justifyContent="center">
                    <TextField
                      id="fleetName"
                      name="fleetName"
                      label="Fleet Name"
                      variant="outlined"
                      required
                      value={values.fleetName}
                      onChange={handleChange}
                      fullWidth
                      error={touched.fleetName && Boolean(errors.fleetName)}
                      helperText={touched.fleetName && errors.fleetName}
                    />
                  </Grid>
                </Grid>
                <Typography
                  id="modal-modal-title"
                  sx={{ padding: "10px", fontStyle: "italic" }}
                >
                  Fleet Manager Details
                </Typography>
                <Divider variant="middle" />
                <Grid container spacing={2} sx={{ padding: "10px" }}>
                  <Grid item container justifyContent="center">
                    <TextField
                      id="fleetManagerFirstName"
                      name="fleetManagerFirstName"
                      label="First name"
                      variant="outlined"
                      required
                      value={values.fleetManagerFirstName}
                      onChange={handleChange}
                      fullWidth
                      error={
                        touched.fleetManagerFirstName &&
                        Boolean(errors.fleetManagerFirstName)
                      }
                      helperText={
                        touched.fleetManagerFirstName &&
                        errors.fleetManagerFirstName
                      }
                    />
                  </Grid>
                  <Grid item container justifyContent="center">
                    <TextField
                      id="fleetManagerLastName"
                      name="fleetManagerLastName"
                      label="Last name"
                      variant="outlined"
                      required
                      value={values.fleetManagerLastName}
                      onChange={handleChange}
                      fullWidth
                      error={
                        touched.fleetManagerLastName &&
                        Boolean(errors.fleetManagerLastName)
                      }
                      helperText={
                        touched.fleetManagerLastName &&
                        errors.fleetManagerLastName
                      }
                    />
                  </Grid>
                  <Grid item container justifyContent="center">
                    <TextField
                      id="fleetManagerEmail"
                      name="fleetManagerEmail"
                      label="Email"
                      variant="outlined"
                      required
                      value={values.fleetManagerEmail}
                      onChange={handleChange}
                      fullWidth
                      error={
                        touched.fleetManagerEmail &&
                        Boolean(errors.fleetManagerEmail)
                      }
                      helperText={
                        touched.fleetManagerEmail && errors.fleetManagerEmail
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="right"
                  alignItems="center"
                  mt={4}
                >
                  <Button onClick={handleClose}>Cancel</Button>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Button
                      type="submit"
                      disabled={!(dirty && isValid)}
                      className={!(dirty && isValid) ? "disabled-btn" : ""}
                    >
                      Add Fleet
                    </Button>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default AdminFleetCreationForm;
