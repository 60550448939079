import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { LinearProgress } from "@mui/material";

const EmptyRequestTable = ({ loading }: { loading?: boolean }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Request #</TableCell>
              <TableCell align="left">Service Type</TableCell>
              <TableCell align="left">Service By</TableCell>
              <TableCell align="left">Company</TableCell>
              <TableCell align="left">VIN</TableCell>
              <TableCell align="left">Vehicle Name</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {loading ? <LinearProgress /> : <TableBody />}
      </TableContainer>
    </>
  );
};

export default EmptyRequestTable;
