export const DownloadFile = (
    { 
        data, 
        fileName, 
        fileType,
    }: {
        data: any;
        fileName: any;
        fileType: any;

    }) => {
    return fetch(`data:${fileType};base64,${data}`)
        .then((res) => res.blob())
        .then((b) => {
            const a = document.createElement("a");
            a.download = fileName 
            a.href = window.URL.createObjectURL(b);
            a.click();
            a.remove();
        });
}