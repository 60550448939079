import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { RequestDto } from "../../../../api/TrailerPMApi.generated";
import {LinearProgress, Tooltip, Typography} from "@mui/material";
import EmptyRequestTable from "../../../global/Placeholders/EmptyRequestTable";
import VinInfoTooltip from "../FleetManagement/VinInfoTooltip";
import StatusChip from "../../../../functions/StatusChip";
import Grid from "@mui/material/Grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useLocation } from "react-router-dom";
import { ToCurrency } from "../../../../functions/DecimalToCurrency";
import { CustomWidthTooltip } from "../../../common/mui/CustomWidthTooltip";
import {useGlobalLoading} from "../../../../context/LoadingContext";

const FleetServiceRequestsTable = ({
  requests,
  handleRedirect,
  loading
}: {
  requests: RequestDto[] | undefined;
  handleRedirect?: any;
  loading?: boolean
}) => {
  const location = useLocation();
  const redirectDisabled = location.state?.redirectDisabled;
  
  return (
    <>
      {!requests || requests.length == 0 ? (
          <div>
            <EmptyRequestTable loading={loading}/>
          </div>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Request #</TableCell>
                <TableCell align="left">Service Type</TableCell>
                <TableCell align="left">Service By</TableCell>
                <TableCell align="left">Provider</TableCell>
                <TableCell align="left">VIN</TableCell>
                <TableCell align="left">Vehicle Name</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests?.map((r: RequestDto) => (
                <TableRow
                  key={r.requestNumber}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    <OpenInNewIcon
                      sx={{ cursor: "pointer" }}
                      color="primary"
                      onClick={() =>
                        !redirectDisabled
                          ? handleRedirect(`/requests/${r.requestNumber}`, r)
                          : null
                      }
                    />
                  </TableCell>
                  <TableCell align="left">{r.requestNumber}</TableCell>
                  <TableCell align="left">{r.serviceType}</TableCell>
                  <TableCell align="left">{`${r.serviceByDate?.toLocaleString()}`}</TableCell>
                  <TableCell align="left">{r.providerName}</TableCell>
                  <TableCell align="left">
                    <CustomWidthTooltip
                      placement="bottom-end"
                      title={<VinInfoTooltip vinInfo={r.vinInfo} />}
                    >
                      <Typography variant="caption" sx={{ cursor: "pointer" }}>
                        {r.vin}
                      </Typography>
                    </CustomWidthTooltip>
                  </TableCell>
                  <TableCell align="left">{r.vehicleName}</TableCell>
                  <TableCell align="left">
                    <Grid item container direction="column">
                      <Grid item>{r.address?.addressLine1}</Grid>
                      <Grid item>
                        {`${r.address?.city}, ${r.address?.state}, ${r.address?.postalCode}`}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="right">{ToCurrency(r?.total)}</TableCell>
                  <TableCell align="left">
                    <StatusChip status={r.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default FleetServiceRequestsTable;
