import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useOktaAuth } from "@okta/okta-react";
import {
  FleetAssetApi,
  RequestApi,
  RequestDto,
} from "../../../../api/TrailerPMApi.generated";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { ChangeEvent, useState, MouseEvent } from "react";
import { useGlobalLoading } from "../../../../context/LoadingContext";
import { DownloadFile } from "../../../../functions/DownloadFile";
import { UserContext, useUserContext } from "../../../../context/UserProvider";

const InspectionFormFileUpload = ({
  request,
}: {
  request: RequestDto | null | undefined;
}) => {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const requestApi = new RequestApi();
  const { currentUser } = useUserContext();
  const [ fileName, setFileName ] = useState<string>(request?.inspectionFormFileName ?? "");
  const { globalLoading, setGlobalLoading } = useGlobalLoading();

  const uploadInspectionForm = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    requestApi.token = token;
    requestApi
      .uploadInspectionForm(request?.requestId, {
        data: file,
        fileName: file.name,
      })
      .catch((err) => {
        setGlobalLoading(false);
      })
      .then((res) => {
        setGlobalLoading(false);
        if (request && res) {
          request.hasInspectionForm = true;
          setFileName(res.fileName ?? "");
        }
      });
  };

  const downloadInspectionForm = (e: MouseEvent) => {
    setGlobalLoading(true);
    requestApi.token = token;
    requestApi.getAnnualInspectionReport(request?.requestId).then((result) => {
      DownloadFile({
        data: result.fileString,
        fileName: result.fileName,
        fileType: result.mimeType,
      });
      setGlobalLoading(false);
    });

    e.preventDefault();
  };

  return (
    <Paper sx={{ height: 300 }}>
      <Box sx={{ padding: 2 }}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item>
              <Typography
                sx={{
                  fontWeight: 200,
                  fontFamily: "Poppins",
                  fontSize: "16pt",
                }}
              >
                Inspection Form
              </Typography>
            </Grid>
            {currentUser?.userType === "ServiceProvider" && (
              <Grid item>
                <Button
                  variant="text"
                  disableRipple
                  disableFocusRipple
                  disableElevation
                  size="small"
                >
                  <a
                    href="/files/Annual_Inspection_Report.pdf"
                    target="_blank"
                    download
                  >
                    Download Inspection Form
                  </a>
                </Button>
              </Grid>
            )}
          </Grid>

          {currentUser?.userType === "ServiceProvider" &&
          request?.status?.name === "In Progress" ? (
            <Grid item container sx={{ my: 2 }}>
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={{ marginRight: "1rem" }}
              >
                Upload Form
                <input
                  type="file"
                  accept="image/*,.pdf"
                  hidden
                  onChange={(e) => {
                    setGlobalLoading(true);
                    uploadInspectionForm(e);
                  }}
                />
              </Button>
            </Grid>
          ) : null}
          {request?.hasInspectionForm ? (
            <Grid item container sx={{ my: 1 }}>
              <Button
                onClick={downloadInspectionForm}
                sx={{ textTransform: "initial" }}
              >
                Download {fileName}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Paper>
  );
};

export default InspectionFormFileUpload;
