import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

const PageNotFound = ({ redirectPage }: any) => {
  return (
    <Grid
      container
      m={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      mt={20}
    >
      <Card
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          width: 600,
          padding: 25,
        }}
      >
        <CardContent>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={6}
          >
            <Grid item>
              <WarningAmberRoundedIcon
                sx={{
                  width: "85px",
                  height: "85px",
                }}
                color="secondary"
              />
            </Grid>
            <Grid item>
              <Typography variant="h2" color="white">
                Page Not Found
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => redirectPage("/")}
              >
                Go Back
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PageNotFound;
