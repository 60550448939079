import { string, object, number, Schema } from "yup";
import { ICreateProviderDto } from "../api/TrailerPMApi.generated";

const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

export const CreateFleetProviderSchema: Schema<any> = object({
  fleetName: string().max(100).required("Fleet name is required"),
  fleetManagerFirstName: string().max(100).required("First name is required"),
  fleetManagerLastName: string().max(100).required("Last name is required"),
  fleetManagerEmail: string().email().max(100).required("Email is required"),
}).defined();
