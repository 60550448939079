import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BuildIcon from "@mui/icons-material/Build";
import HoursTooltip from "./HoursTooltip";
import {
  RequestApi,
  CreateRequestDto,
  FleetAssetDto,
  ServiceProviderDto,
  AddressDto,
  ProviderOfferingDto,
} from "../../../../api/TrailerPMApi.generated";
import { useOktaAuth } from "@okta/okta-react";
import { toast } from "react-toastify";

export default function ServiceProviderCard({
  provider,
  requestValues,
  asset,
  handleClose,
}: {
  provider: ProviderOfferingDto;
  asset: FleetAssetDto;
  requestValues: any;
  handleClose: () => void;
}) {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const requestApi = new RequestApi();

  let data = {
    assetId: asset.fleetAssetId,
    providerId: provider?.provider?.serviceProviderId,
    serviceId: requestValues.service,
    startWindow: requestValues.requestStartWindow,
    endWindow: requestValues.requestEndWindow ?? requestValues.requestStartWindow,
    serviceRate: provider.hourlyRate,
    serviceHours: provider.hours,
    total: provider?.totalCost,
    serviceAddressId: 1,
    requestAddress: new AddressDto({
      type: requestValues.type,
      addressLine1: requestValues.addressLine1,
      city: requestValues.city,
      state: requestValues.state.toLocaleUpperCase(),
      postalCode: requestValues.postalCode,
    }),
  };

  const scheduleService = async () => {
    requestApi.token = token;
    requestApi
      .createRequest(new CreateRequestDto(data))
      .then((data) => {
        toast.success(`Service Request booked for ${asset.name}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        handleClose();
      })
      .catch((error) => {
        toast.error(`Error in booking request for ${asset.name}: ${error}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        handleClose();
      });
  };

  return (
    <Card
      sx={{ maxWidth: "100%", border: 0.5, borderColor: "rgb(189, 189, 189)" }}
    >
      <CardContent sx={{ p: 1, "&:last-child": { pb: 1 } }}>
        <Grid container direction="row">
          <Grid item xs={1}>
            <BuildIcon sx={{ height: 50, width: 50, pt: 1 }} />
          </Grid>
          <Grid item xs={5} pl={1}>
            <Typography variant="body1">{provider?.provider?.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {provider?.provider?.contactPhone}
            </Typography>
            <HoursTooltip hours={provider?.provider?.businessHours}>
              <Typography variant="body2" sx={{ color: "secondary.dark" }}>
                Hours
              </Typography>
            </HoursTooltip>
          </Grid>
          <Grid item xs={4} container pl={2} flexDirection="column">
            <Typography variant="h4" sx={{ color: "warning.main" }}>
              ${provider.totalCost}
            </Typography>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <CardActions>
              <Button
                size="small"
                variant="contained"
                onClick={scheduleService}
                color="success"
              >
                Schedule
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
