import React, {useEffect, useState} from "react";
import Tooltip from '@mui/material/Tooltip';
import {ConvertUTCTime} from "../../../../functions/ConvertUTCTime";


const HoursTooltip = ({children, hours}: any)=>{
    
    let stringValue : any = []
    
    if(hours.length > 0 ){
        hours.map((x: any)=> {
            if (x.openValue === true) {
                let open =new Date(x.openTime.toString()).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                let close = new Date(x.closeTime.toString()).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                stringValue.push({ "weekday" :x.dayOfWeek , "hours" :`${open} - ${close}`})
            } else if (x.openValue === false){
                stringValue.push({ "weekday" :x.dayOfWeek , "hours" :`Closed`})
            }
        })
    }
    
    const getHourString = (day: string)  =>{
      if(stringValue.length > 0){
         let x =  stringValue?.find((element : any)  => element.weekday === day)
          return x.hours
      }
    }
    
    let text =`Sunday: ${getHourString("Sunday")}
                       Monday: ${getHourString("Monday")} 
                       Tuesday: ${getHourString("Tuesday")} 
                       Wednesday: ${getHourString("Wednesday")} 
                       Thursday: ${getHourString("Thursday")} 
                       Friday: ${getHourString("Friday")} 
                       Saturday: ${getHourString("Saturday")}`
    
    
    let noHours = "Business Hours Unavailable"
    
    
    return(
        <Tooltip placement='bottom-start' title={<span style={{ whiteSpace: 'pre-line', fontSize: 14 }}>{hours.length > 0 ? text : noHours}</span>}>
            {children}
        </Tooltip>
    )
}
export default HoursTooltip