import { string, object, number, Schema } from "yup";
import { ICreateProviderDto } from "../api/TrailerPMApi.generated";

const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

export const CreateServiceProviderSchema: Schema<ICreateProviderDto> = object({
  providerName: string().max(100).required("Provider name is required"),
  contactFirstName: string().required("First name is required") ,
  contactLastName: string().required("Last name is required") ,
  contactEmail: string()
    .email("Must be a valid email")
    .required("Email is required"),
  contactPhone: string()
    .matches(phoneRegExp, "Must be a valid phone number")
    .nullable()
    .notRequired(),
  addressLine1: string().max(100).nullable().notRequired(),
  addressLine2: string().max(100).nullable().notRequired(),
  city: string().nullable().notRequired(),
  state: string().required("State is required."),
  postalCode: string()
    .matches(/^\d{5}(?:[-\s]\d{4})?$/, {
      message: "Must be a valid US Zip Code",
      excludeEmptyString: true,
    })
    .required(),
}).defined();
