import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import ProviderRequestsTable from "./ProviderRequestsTable";
import ProviderInProgressTable from "./ProviderInProgressTable";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import { PageTitle } from "../../../functions/PageTitleFunction";
import { useOktaAuth } from "@okta/okta-react";
import {
  RequestDto,
  ProviderApi,
  RequestApi,
  RequestStatusEnum,
  GetProviderRequestListDto,
} from "../../../api/TrailerPMApi.generated";
import { useUserContext } from "../../../context/UserProvider";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NonBillingRequestEnums } from "../../../functions/RequestEnumFunctions";

const ProviderRequests = () => {
  PageTitle("Service Requests");
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const providerApi = new ProviderApi();
  const requestApi = new RequestApi();
  requestApi.token = token;
  providerApi.token = token;

  //state
  const [pendingRequests, setPendingRequests] = useState<RequestDto[]>([]);
  const [inProgressRequests, setInProgressRequests] = useState<RequestDto[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectDisabled, setRedirectDisabled] = useState<boolean>(true);
  const handleRedirect = (route: string) => {
    setRedirectDisabled(true);
    navigate(`${route}`, { state: { redirectDisabled } });
  };

  const handleUpdateRequestStatus = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    statusId: RequestStatusEnum,
    r: RequestDto,
    type?: string
  ) => {
    e?.stopPropagation();
    if (type !== "start") {
      setLoading(true);
    }
    requestApi
      .updateFleetRequestStatus(r?.requestId, statusId)
      .then((req: RequestDto) => {
        toast.success(`Request #${r?.requestNumber} has been updated`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        if (type === "start") {
          handleRedirect(`/requests/${req.requestNumber}`);
        } else {
          let updatedPendingRequests = pendingRequests.filter(
            (x) => x.requestId != r?.requestId
          );
          setPendingRequests(updatedPendingRequests);
          setInProgressRequests((prev) => [...prev, req]);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(
          `Could not process update for request #${r?.requestNumber}: ${err}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          }
        );
        setLoading(false);
      });
  };

  const getRequests = () => {
    if (currentUser?.serviceProviderId) {
      providerApi
        .getRequestsForServiceProvider(
          new GetProviderRequestListDto({
            providerId: currentUser.serviceProviderId,
            filteredStatuses: NonBillingRequestEnums(),
          })
        )
        .then((r: RequestDto[]) => {
          let x = r.filter((r) => r.status?.name === "Requested");
          let y = r.filter((r) => r.status?.name !== "Requested");
          setPendingRequests(x);
          setInProgressRequests(y);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (currentUser != null) {
      getRequests();
    }
  }, [currentUser]);

  return (
    <Grid container>
      <Grid item container direction="column">
        <Grid item></Grid>
      </Grid>
      <Grid container direction="column" mt={4}>
        <Grid item container direction="column">
          <Grid item>
            <Typography>Pending Requests</Typography>
          </Grid>
          <Grid item mt={2}>
            <ProviderRequestsTable
              requests={pendingRequests}
              loading={loading}
              handleUpdateRequestStatus={handleUpdateRequestStatus}
              handleRedirect={handleRedirect}
            />
          </Grid>
        </Grid>
        <Grid item container direction="column" mt={4}>
          <Grid item>
            <Typography>In Progress Requests</Typography>
          </Grid>
          <Grid item mt={2}>
            <ProviderInProgressTable
              requests={inProgressRequests}
              loading={loading}
              handleUpdateRequestStatus={handleUpdateRequestStatus}
              handleRedirect={handleRedirect}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProviderRequests;
