import { RequestStatusEnum } from "../api/TrailerPMApi.generated";

export const NonBillingRequestEnums = () => {
  return [
    RequestStatusEnum.Requested,
    RequestStatusEnum.Accepted,
    RequestStatusEnum.Declined,
    RequestStatusEnum.Booked,
    RequestStatusEnum.InProgress,
    RequestStatusEnum.Estimated,
  ];
};

export const BillingRequestEnums = () => {
  return [RequestStatusEnum.Invoiced, RequestStatusEnum.Completed];
};
