import React, {useEffect, useState} from 'react';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import {GetComparator, SortRowInformation} from "./helpers/SortHelpers";
import TableRow from "@mui/material/TableRow";
import {ColDefMonoTable} from "../../../interfaces/interfaces";
import TableCell from "@mui/material/TableCell";
import TableHeader from "./TableHeader/TableHeader";
import {Grid, LinearProgress} from "@mui/material";
import AssetRow from "./TableRow/AssetRow";
import {useGlobalLoading} from "../../../context/LoadingContext";
import AddressDtoTableDisplay from "../dto-displays/AddressDtoTableDisplay";

const MonoTable = ({ columns, rows, title }: { columns: ColDefMonoTable; rows: any; title: string; }) => {
    
    const [orderDirection, setOrderDirection] = useState("asc");
    const [valueToOrderBy, setValueToOrderBy] = useState("name");
    const { loadingData } = useGlobalLoading();

    const handleSort = (event: any, property: any) => {
        const isAscending = valueToOrderBy === property && orderDirection === "asc";
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? "desc" : "asc");
    };
    
    return (
        <Paper>
            <TableContainer>
                    {loadingData ? (
                        <>
                            <Table>
                                <TableHeader
                                    valueToOrderBy={valueToOrderBy}
                                    orderDirection={orderDirection}
                                    handleSort={handleSort}
                                    columns={columns}
                                    title={title}
                                />
                            </Table>
                            <LinearProgress />
                        </>
                    ) :(
                    <Table>
                        <TableHeader
                            valueToOrderBy={valueToOrderBy}
                            orderDirection={orderDirection}
                            handleSort={handleSort}
                            columns={columns}
                            title={title} />
                        {SortRowInformation(rows, GetComparator(orderDirection, valueToOrderBy)).map((row: any) => {
                            if (title === "Fleet Management" || title === "Fleet Admin") {
                                return (<AssetRow row={row} key={row?.fleetAssetId} columns={columns} title={title}/>)
                            } else {
                                return (
                                    <TableRow>
                                        {columns.map((col) => {
                                            if (col.name.includes('address')) {
                                                return (
                                                    <TableCell align="left">
                                                        <AddressDtoTableDisplay address={row[col.name]} />
                                                    </TableCell>
                                                )
                                            }
                                            return (<TableCell align="left">{row[col.name]}</TableCell>)
                                        })}
                                    </TableRow>
                                )
                            }
                        })}
                    </Table>
                    )}
            </TableContainer>
        </Paper>
    );
};

export default MonoTable;
