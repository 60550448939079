import React, {
  useState,
  MouseEvent,
  useEffect,
  ChangeEvent,
  useContext,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import {
  CreateProviderDto,
  ICreateProviderDto,
  ProviderApi,
} from "../../../../api/TrailerPMApi.generated";
import { useOktaAuth } from "@okta/okta-react";
import { StatesContext } from "../../../../context/StatesProvider";
import { Formik, Form, Field, ErrorMessage, getIn } from "formik";
import { CreateServiceProviderSchema } from "../../../../validation/CreateServiceProviderSchema";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function getDefaultProvider(): ICreateProviderDto {
  return {
    providerName: "",
    contactFirstName: "",
    contactLastName: "",
    contactEmail: "",
    contactPhone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "NC",
    postalCode: "",
  };
}

const CreateProviderModal = ({ updateProviders }: any) => {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const providerApi = new ProviderApi();

  const { statesInfo } = useContext(StatesContext);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const saveProvider = (data: ICreateProviderDto) => {
    providerApi.token = token;
    providerApi
      .createServiceProvider(new CreateProviderDto(data))
      .catch((error) => {})
      .then(async (newProvider) => {
        await updateProviders();
        handleClose();
      });
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Create Service Provider
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={getDefaultProvider()}
            validationSchema={CreateServiceProviderSchema}
            onSubmit={(values, actions) => {
              //alert(JSON.stringify(values, null, 2));
              saveProvider(values);
              actions.setSubmitting(false);
            }}
          >
            {(formik) => {
              const {
                errors,
                touched,
                isValid,
                dirty,
                handleChange,
                setFieldValue,
              } = formik;
              return (
                <Form>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    align="left"
                  >
                    Add New Service Provider
                  </Typography>
                  <Grid container spacing={2} mt={2}>
                    <Grid item container justifyContent="center" xs={12}>
                      <TextField
                        id="providerName"
                        name="providerName"
                        label="Provider Name"
                        variant="outlined"
                        required
                        value={formik.values.providerName}
                        onChange={handleChange}
                        fullWidth
                        error={touched.providerName && Boolean(errors.providerName)}
                        helperText={touched.providerName && errors.providerName}
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={6}>
                      <TextField
                        id="contactEmail"
                        name="contactEmail"
                        label="Email"
                        value={formik.values.contactEmail}
                        onChange={handleChange}
                        fullWidth
                        error={
                          touched.contactEmail && Boolean(errors.contactEmail)
                        }
                        helperText={touched.contactEmail && errors.contactEmail}
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={6}>
                      <TextField
                          id="contactPhone"
                          name="contactPhone"
                          label="Phone"
                          value={formik.values.contactPhone}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          error={
                              touched.contactPhone && Boolean(errors.contactPhone)
                          }
                          helperText={touched.contactPhone && errors.contactPhone}
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={6}>
                      <TextField
                        id="contactFirstName"
                        name="contactFirstName"
                        label="First Name"
                        value={formik.values.contactFirstName}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={
                          touched.contactFirstName && Boolean(errors.contactFirstName)
                        }
                        helperText={touched.contactFirstName && errors.contactFirstName}
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={6}>
                      <TextField
                        id="contactLastName"
                        name="contactLastName"
                        label="Last Name"
                        value={formik.values.contactLastName}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={
                          touched.contactLastName && Boolean(errors.contactLastName)
                        }
                        helperText={touched.contactLastName && errors.contactLastName}
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={12}>
                      <TextField
                        id="addressLine1"
                        name="addressLine1"
                        label="Address Line 1"
                        value={formik.values.addressLine1}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={
                          touched.addressLine1 && Boolean(errors.addressLine1)
                        }
                        helperText={touched.addressLine1 && errors.addressLine1}
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={12}>
                      <TextField
                        id="addressLine2"
                        name="addressLine2"
                        label="Address Line 2"
                        value={formik.values.addressLine2}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={
                          touched.addressLine2 && Boolean(errors.addressLine2)
                        }
                        helperText={touched.addressLine2 && errors.addressLine2}
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={4}>
                      <TextField
                        id="city"
                        name="city"
                        label="City"
                        value={formik.values.city}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={4}>
                      <Autocomplete
                        autoHighlight
                        disablePortal
                        id="state"
                        options={statesInfo}
                        value={formik.values.state}
                        onChange={(e, value) => {
                          setFieldValue("state", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            label="State*"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item container justifyContent="center" xs={4}>
                      <TextField
                        id="postalCode"
                        name="postalCode"
                        label="Zip"
                        value={formik.values.postalCode}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                        error={touched.postalCode && Boolean(errors.postalCode)}
                        helperText={touched.postalCode && errors.postalCode}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container justifyContent="right" mt={4}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      type="submit"
                      disabled={!(dirty && isValid)}
                      className={!(dirty && isValid) ? "disabled-btn" : ""}
                    >
                      Save Provider
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateProviderModal;
