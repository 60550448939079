import React, {useState} from 'react';
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {TableSortLabel} from "@mui/material";

const TableHeader = 
    ({
        valueToOrderBy,
        orderDirection,
        handleSort,
        columns,
        title
    }:{
        valueToOrderBy?: any;
        orderDirection?: any;
        handleSort?: any;
        columns ?: any;
        title?: string;
    }) => {
    const createSortHandler = (property: any) => (event: any) =>  {
        handleSort(event, property);
    }
    const handleHeaderMap = (columns: any) => {
        return columns.map((column: any) => {
            if (column.name.includes("Address") || column.name.includes("address") || title === "Request Details") {
                return (
                    <TableCell key={column.name}>{column.label}</TableCell>
                )
            }
                return (
                    <TableCell
                        key={column.name}
                    >
                        <TableSortLabel
                            active={valueToOrderBy === column.name}
                            direction={valueToOrderBy == column.name ? orderDirection : 'asc'}
                            onClick={createSortHandler(column.name)}
                        >
                            {column.label}
                        </TableSortLabel>
                    </TableCell>
                )
        })
    }
    
    return (
        <TableHead>
            {title?.includes("Fleet") ? title?.includes("Admin") ? (
                <>
                    <TableCell sx={{padding: 2}} />
                    {handleHeaderMap(columns)}
                </>
            ) : (
                <>
                    <TableCell sx={{padding: 2}} />
                    {handleHeaderMap(columns)}
                    <TableCell>Schedule Service Provider</TableCell>
                </>
            ) : handleHeaderMap(columns)}
        </TableHead>
    );
};

export default TableHeader;
